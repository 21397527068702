@import "../../variables.scss";
@media screen and (min-width: $breakpoint-mobile) {
    .songs{
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 32px;
        // background: red;
        box-sizing: border-box;
        align-self: stretch;
        height: fit-content;
    }
    .mobilePagination{
        display: none;
    }
    .pagination{
        display: block;
    }
    
}
@media screen and (max-width: $breakpoint-mobile) {
    .songs{
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 32px;
        // background: red;
        box-sizing: border-box;
        align-self: stretch;
        height: fit-content;
        .table{
            width: 100%;
            overflow: scroll;
            *{
                text-wrap: nowrap;
            }
        }
    }
    .mobilePagination{
        display: block;
    }
    .pagination{
        display: none;
    }
}
@import '../../../styles/variables.scss';
@media screen and (min-width: $breakpoint-mobile) {
    .mobileBtn {
        display: none;
    }

    .upload {

        display: flex;
        padding: 40px 32px 48px 32px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        border-radius: 20px;
        border: 1px solid #373737;
        box-sizing: border-box;
        gap: 24px;

        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;
            align-self: stretch;

            // width: 100%;
            // background-color: gold;
            .mobileBtn {
                display: none;
            }

            .text {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 4px;
                align-self: stretch;

                h1 {
                    color: #EE4950;
                    text-align: center;

                    /* Text md/Semibold */
                    font-family: Inter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    /* 150% */
                    margin: 0;
                }

                p {
                    color: #ADB0B2;
                    text-align: center;

                    /* Text sm/Regular */
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    margin: 0;
                    /* 142.857% */
                    width: 350px;
                }
            }

            .mobileBtn {
                display: none;
            }
        }
    }

    .uploadInfoModal {
        display: flex;
        width: 100%;
        border: 1px solid #373737;
        padding: 30px;
        box-sizing: border-box;

        .content {
            width: 100%;

            .progress {

                display: flex;
                justify-content: center;
                width: 100%;

                .uploading {
                    font-size: 16px;
                    font-weight: 600;
                    color: #fff;
                    margin: 0;
                    padding: 0;
                    text-align: center;
                    font-family: Inter;
                }
            }

            .action {
                display: flex;
                justify-content: space-between;
                // background: red;
                width: 100%;
                flex-wrap: wrap;

                .lastDate,

                .delete {
                    font-size: 16px;
                    font-weight: 600;
                    color: #fff;
                    margin: 0;
                    padding: 0;
                    // text-align: center;
                    font-family: Inter;
                    width: 110px;
                    text-wrap: nowrap;
                }

                .songsState {
                    font-size: 14px;
                    font-weight: 500;
                    color: #fff;
                    margin: 0;
                    margin-top: 5px;
                    padding: 0;
                    // text-align: center;
                    font-family: Inter;
                    width: 200px;
                    text-wrap: nowrap;
                }


                .delete {
                    background: transparent;
                    border: none;
                    color: #EE4950;
                    cursor: pointer;
                }
            }

            .note {

                b {
                    color: orange
                }

                ul {
                    color: whitesmoke;
                    margin-top: 5px;
                    font-family: Inter;
                    font-size: 12px;
                    font-weight: normal;
                    li{
                        margin-bottom: 3px;
                    }
                }
            }

        }
    }
}

@media screen and (max-width: $breakpoint-mobile) {
    .btn {
        display: none !important;
    }

    .upload {
        display: flex;
        padding: 20px 10px 24px 10px;
        box-sizing: border-box;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        border-radius: 20px;
        border: 1px solid #373737;
        box-sizing: border-box;
        gap: 24px;
        width: 100%;

        // background: #ADB0B2;
        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;
            align-self: stretch;
            width: 100%;
            box-sizing: border-box;

            // background-color: gold;
            .text {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                gap: 4px;
                align-self: stretch;

                h1 {
                    color: #EE4950;
                    text-align: center;

                    /* Text md/Semibold */
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    /* 150% */
                    margin: 0;
                }

                p {
                    color: #ADB0B2;
                    text-align: center;

                    /* Text sm/Regular */
                    font-family: Inter;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    margin: 0;
                    /* 142.857% */
                    width: 80%;
                }
            }
        }
    }

    .btn {
        display: none;
    }

    .uploadInfoModal {
        display: flex;
        width: 100%;
        border: 1px solid #373737;
        padding: 30px;
        box-sizing: border-box;

        .content {
            width: 100%;

            .progress {

                display: flex;
                justify-content: center;
                width: 100%;

                .uploading {
                    font-size: 16px;
                    font-weight: 600;
                    color: #fff;
                    margin: 0;
                    padding: 0;
                    text-align: center;
                    font-family: Inter;
                }
            }

            .action {
                display: flex;
                justify-content: space-between;
                // background: red;
                width: 100%;

                .lastDate,
                .totalSongs,
                .delete {
                    font-size: 16px;
                    font-weight: 600;
                    color: #fff;
                    margin: 0;
                    padding: 0;
                    // text-align: center;
                    font-family: Inter;
                    width: 110px;
                    text-wrap: nowrap;
                }

                .delete {
                    background: transparent;
                    border: none;
                    color: #EE4950;
                    cursor: pointer;
                }
            }
        }
    }
}

.overview {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;

    .video {
        width: 100%;
        height: 200px;
    }

    .details {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .label {
            color: var(--gray-white, #FFF);

            /* tittle/16 M */
            font-family: "DM Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        .selectThumbnail {
            display: flex;
            padding: 16px 24px;
            box-sizing: border-box;

            flex-direction: column;
            align-items: center;
            gap: 12px;
            align-self: stretch;
            border-radius: 8px;
            border: 1px dashed #454647;
            cursor: pointer;

            .thumbnail {
                width: 150px;
                height: 100px;
            }

            .uploadText {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 4px;
                align-self: stretch;
                cursor: pointer;

                .line1 {
                    color: #FFF;

                    /* Text sm/Semibold */
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px;
                    margin: 0;

                    /* 142.857% */
                    span {
                        color: #707276;

                        /* Text sm/Regular */
                        font-family: Inter;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                        /* 142.857% */
                    }
                }

                .line2 {
                    color: #707276;
                    text-align: center;

                    /* Text xs/Regular */
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                    margin: 0;
                    /* 150% */
                }
            }
        }

        .textArea {
            display: flex;
            height: 180px;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
            align-self: stretch;

            label {
                color: var(--gray-white, #FFF);

                /* tittle/16 M */
                font-family: "DM Sans";
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            textarea {
                display: flex;
                padding: 12px 16px;
                box-sizing: border-box;

                align-items: flex-start;
                gap: 10px;
                flex: 1 0 0;
                align-self: stretch;
                border-radius: 10px;
                border: 1px solid #373737;
                outline: none;
                background: transparent;


                color: #C4C4C4;
                text-align: justify;

                /* tittle/16 R */
                font-family: "DM Sans";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .instruction {
                align-self: stretch;
                color: #494C4E;
                text-align: right;

                /* tittle/16 R */
                font-family: "DM Sans";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .error {
                font-family: Inter;
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
                text-align: left;
                color: #EB5757;
                padding: 0;
                margin: 0;
            }
        }

        .btns {
            display: flex;
            align-items: flex-start;
            gap: 12px;
            align-self: stretch;

            .cancel {
                display: flex;
                padding: 10px 18px;
                box-sizing: border-box;

                justify-content: center;
                align-items: center;
                gap: 8px;
                flex: 1 0 0;
                border-radius: 8px;

                /* Shadow/xs */
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                border: none;
                background: transparent;
                color: #929292;

                /* Text md/Semibold */
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                /* 150% */
                cursor: pointer;
            }

            .publish {
                display: flex;
                padding: 10px 18px;
                box-sizing: border-box;

                justify-content: center;
                align-items: center;
                gap: 8px;
                flex: 1 0 0;
                border-radius: 8px;
                border: 1px solid #232323;
                background: #232323;

                /* Shadow/xs */
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                color: #535151;

                /* Text md/Semibold */
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                /* 150% */
                cursor: pointer;
            }

            .red {
                border-radius: 8px;
                border: 1px solid #EE4950;
                background: #EE4950;

                /* Shadow/xs */
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                color: var(--White, #FFF);

                /* Text md/Semibold */
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                /* 150% */
            }
        }
    }
}
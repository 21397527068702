@import "../../variables.scss";
// @import url('https://fonts.cdnfonts.com/css/bauhaus-93');
@media screen and (min-width: $breakpoint-tablet) {

    .container {
        width: 100%;
        height: 100vh;
        display: flex;
        overflow: hidden;
        .sidebar {
            width: 300px;
            height: 100vh;
            z-index: 10;
            display: flex;
            flex-direction: column;
            background: #1B1A1A;
            justify-content: space-between;
            flex: 0 0 1;
            min-height: 100vh;

            .top {
                .logo {
                    display: flex;
                    padding-top: 32px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    padding-bottom: 32px;
                    align-self: stretch;
                    width: 100%;
                    box-sizing: border-box;
                    cursor: pointer;

                    h1 {
                        color: var(--neutral-1000-night, #FAFAFA);
                        text-align: center;
                        font-family: "Bauhaus 93";
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        margin: 0;
                        width: 100%;
                        box-sizing: border-box;
                        img{
                            width: 70%;
                        }
                    }
                }

                hr {
                    height: 1px;
                    flex-shrink: 0;
                    width: 100%;
                    margin: 0;
                    border: none;
                    background: #252525;
                }

                .menu {
                    display: flex;
                    list-style: none;
                    flex-direction: column;
                    width: 100%;
                    justify-content: center;
                    padding: 0 24px;
                    box-sizing: border-box;
                    gap: 16px;

                    .menuItem {

                        display: flex;
                        // height: 30px;
                        padding: 5px 24px;
                        align-items: center;
                        gap: 10px;
                        flex-shrink: 0;
                        align-self: stretch;
                        border-radius: 8px;
                        cursor: pointer;

                        a {
                            text-decoration: none;
                            color: var(--neutral-1000-night, #FAFAFA);
                            font-family: Inter;
                            font-size: 19.075px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                        }
                    }

                    .active {
                        background: var(--branding-red-tint-200, #EE4950);

                    }
                }
            }

            .bottom {

                display: flex;
                padding: 0px 24px;
                // background-color: #EE4950;
                align-items: center;
                gap: 10px;
                flex-shrink: 0;
                align-self: stretch;
                width: 100%;

                box-sizing: border-box;

                div {
                    display: flex;
                    height: 48px;
                    padding: 0px 24px;
                    align-items: center;
                    gap: 10px;
                    flex-shrink: 0;
                    align-self: stretch;
                    border-radius: 8px;
                    cursor: pointer;

                    .logout {
                        color: #FFF;
                        font-family: Inter;
                        font-size: 19.075px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }
                }
            }
        }
        .mobileSidebar{
            display: none;
        }

        .content {
            flex: 1;
            display: flex;
            flex-direction: column;
            .navbar {
                background: transparent;
                width: 100%;
                padding: 32px;
                padding-bottom: 26.5px;
                box-sizing: border-box;
                display: flex;

                align-items: center;
                justify-content: space-between;
                align-self: stretch;
                height: fit-content;
                position: relative;

                .left {
                    h1 {
                        color: var(--neutral-1000-night, #FAFAFA);
                        font-family: Inter;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        padding: 10px 0;
                        margin: 0;
                    }
                }

                .right {
                    display: flex;
                    gap: 32px;
                    height: 100%;

                    hr {
                        width: 0.998px;
                        align-self: stretch;
                        background: #252525;
                        height: 50px;
                    }
                }


            }
            .mobileNavbar{
                display: none;
            }

            .children {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                padding: 32px;
                box-sizing: border-box;
                flex: 1;
                overflow-y: scroll;
            }

            hr {
                height: 1px;
                flex-shrink: 0;
                width: 100%;
                margin: 0;
                border: none;
                background: #252525;
            }
        }

        .selector {
            display: flex;
            padding: 12px 20px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            cursor: pointer;
            position: relative;

            .selected {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 16px;

                .name {
                    width: 50px;
                    color: #FFF;
                    font-family: Inter;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    /* 120% */
                }
            }

            .dropdown {
                position: absolute;
                bottom: -180px;
                left: -5px;
                display: none;
                flex-direction: column;
                width: 190px;
                align-items: flex-start;
                border-radius: 0px 0px 8px 8px;
                border-right: 1px solid #252525;
                border-bottom: 1px solid #252525;
                border-left: 1px solid #252525;
                background: #1B1A1A;
                box-sizing: border-box;
                z-index: 10;
                /* Shadow/lg */
                box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);

                &:hover {
                    display: flex;
                }

                div {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 12px;
                    flex: 1 0 0;

                    padding: 10px 14px;

                    align-self: stretch;
                    color: #FFF;
                    font-family: Inter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 24px;

                    /* 150% */
                    span {
                        display: flex;
                        align-items: center;
                        gap: 12px;
                    }

                    &:hover {
                        background: #252525;
                    }

                    img:nth-child(2) {
                        display: none;
                    }
                }

                .active {
                    background: #EE4950;

                    img:nth-child(2) {
                        display: block;
                    }
                }
            }
        }

        .profile {
            display: flex;
            align-items: center;
            gap: 24px;
            cursor: pointer;

            .name {
                color: #FFF;
                text-align: right;
                font-family: Inter;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            img {
                width: 48px;
                height: 48px;
                border-radius: 48px;
                border: 2px solid #EE4950;
            }
        }
    }
}
@media screen and (min-width: $breakpoint-mobile) and (max-width: $breakpoint-tablet) {

    .container {
        width: 100%;
        height: 100vh;
        display: flex;
        overflow: hidden;
        .sidebar {
            width: fit-content;
            height: 100vh;
            z-index: 10;
            display: flex;
            flex-direction: column;
            background: #1B1A1A;
            justify-content: space-between;
            flex: 0 0 1;
            min-height: 100vh;

            .top {
                .logo {
                    display: flex;
                    padding-top: 18px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    padding-bottom: 32px;
                    align-self: stretch;
                    width: 100%;
                    box-sizing: border-box;
                    cursor: pointer;

                    h1 {
                        color: var(--neutral-1000-night, #FAFAFA);
                        text-align: center;
                        font-family: "Bauhaus 93";
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        margin: 0;
                        width: 100%;
                        box-sizing: border-box;
                        img{
                            width: 70%;
                        }
                    }
                }

                hr {
                    height: 1px;
                    flex-shrink: 0;
                    width: 100%;
                    margin: 0;
                    border: none;
                    background: #252525;
                }

                .menu {
                    display: flex;
                    list-style: none;
                    flex-direction: column;
                    width: 100%;
                    justify-content: center;
                    padding: 0 24px;
                    box-sizing: border-box;
                    gap: 10px;

                    .menuItem {

                        display: flex;
                        // height: 30px;
                        padding: 5px 24px;
                        align-items: center;
                        gap: 10px;
                        flex-shrink: 0;
                        align-self: stretch;
                        border-radius: 8px;
                        cursor: pointer;

                        a {
                            text-decoration: none;
                            color: var(--neutral-1000-night, #FAFAFA);
                            font-family: Inter;
                            font-size: 16.075px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                        }
                    }

                    .active {
                        background: var(--branding-red-tint-200, #EE4950);

                    }
                }
            }

            .bottom {

                display: flex;
                padding: 0px 24px;
                align-items: center;
                gap: 10px;
                flex-shrink: 0;
                align-self: stretch;
                width: 100%;

                box-sizing: border-box;

                div {
                    display: flex;
                    height: 48px;
                    padding: 0px 24px;
                    align-items: center;
                    gap: 10px;
                    flex-shrink: 0;
                    align-self: stretch;
                    border-radius: 8px;
                    cursor: pointer;

                    .logout {
                        color: #FFF;
                        font-family: Inter;
                        font-size: 16.075px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }
                }
            }
        }
        .mobileSidebar{
            display: none;
        }

        .content {
            flex: 1;
            display: flex;
            flex-direction: column;
            .navbar {
                background: transparent;
                width: 100%;
                padding: 18px;
                padding-bottom: 26.5px;
                box-sizing: border-box;
                display: flex;

                align-items: center;
                justify-content: space-between;
                align-self: stretch;
                height: fit-content;
                position: relative;

                .left {
                    h1 {
                        color: var(--neutral-1000-night, #FAFAFA);
                        font-family: Inter;
                        font-size: 17px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        padding: 10px 0;
                        margin: 0;
                    }
                }

                .right {
                    display: flex;
                    gap: 20px;
                    height: 100%;

                    hr {
                        width: 0.998px;
                        align-self: stretch;
                        background: #252525;
                        height: 50px;
                    }
                }


            }
            .mobileNavbar{
                display: none;
            }

            .children {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                padding: 10px;
                box-sizing: border-box;
                flex: 1;
                overflow-y: scroll;
            }

            hr {
                height: 1px;
                flex-shrink: 0;
                width: 100%;
                margin: 0;
                border: none;
                background: #252525;
            }
        }

        .selector {
            display: flex;
            padding: 12px 20px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            cursor: pointer;
            position: relative;

            .selected {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 16px;

                .name {
                    width: 50px;
                    color: #FFF;
                    font-family: Inter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    /* 120% */
                }
            }

            .dropdown {
                position: absolute;
                bottom: -180px;
                left: -5px;
                display: none;
                flex-direction: column;
                width: 190px;
                align-items: flex-start;
                border-radius: 0px 0px 8px 8px;
                border-right: 1px solid #252525;
                border-bottom: 1px solid #252525;
                border-left: 1px solid #252525;
                background: #1B1A1A;
                box-sizing: border-box;
                z-index: 10;
                /* Shadow/lg */
                box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);

                &:hover {
                    display: flex;
                }

                div {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 12px;
                    flex: 1 0 0;

                    padding: 10px 14px;

                    align-self: stretch;
                    color: #FFF;
                    font-family: Inter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 24px;

                    /* 150% */
                    span {
                        display: flex;
                        align-items: center;
                        gap: 12px;
                    }

                    &:hover {
                        background: #252525;
                    }

                    img:nth-child(2) {
                        display: none;
                    }
                }

                .active {
                    background: #EE4950;

                    img:nth-child(2) {
                        display: block;
                    }
                }
            }
        }

        .profile {
            display: flex;
            align-items: center;
            gap: 24px;
            cursor: pointer;

            .name {
                color: #FFF;
                text-align: right;
                font-family: Inter;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            img {
                width: 36px;
                height: 36px;
                border-radius: 48px;
                border: 2px solid #EE4950;
            }
        }
    }
}
@media screen and (max-width: $breakpoint-mobile) {

    .container {
        width: 100%;
        height: 100%;
        display: flex;

        .mobileSidebar {
            width: 300px;
            // height: 100vh;
            z-index: 10;
            display: flex;
            position: absolute;
            flex-direction: column;
            background: #1B1A1A;
            justify-content: space-between;
            box-sizing: border-box;
            flex: 0 0 1;
            height: fit-content;
            left: -100%;
            transition: left 0.3s ease-in-out;
            .top {
                .logo {
                    display: flex;
                    padding-top: 32px;
                    
                    justify-content: space-between;
                    align-items: center;
                    padding: 32px;
                    align-self: stretch;
                    width: 100%;
                    box-sizing: border-box;
                    cursor: pointer;

                    h1 {
                        color: var(--neutral-1000-night, #FAFAFA);
                        text-align: center;
                        font-family: "Bauhaus 93";
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        margin: 0;
                        // width: 100%;
                        box-sizing: border-box;
                    }
                }

                hr {
                    height: 1px;
                    flex-shrink: 0;
                    width: 100%;
                    margin: 0;
                    border: none;
                    background: #252525;
                }

                .menu {
                    display: flex;
                    list-style: none;
                    flex-direction: column;
                    width: 100%;
                    justify-content: center;
                    padding: 0 24px;
                    box-sizing: border-box;
                    gap: 16px;

                    .menuItem {

                        display: flex;
                        // height: 30px;
                        padding: 5px 24px;
                        align-items: center;
                        gap: 10px;
                        flex-shrink: 0;
                        align-self: stretch;
                        border-radius: 8px;
                        cursor: pointer;

                        a {
                            text-decoration: none;
                            color: var(--neutral-1000-night, #FAFAFA);
                            font-family: Inter;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                        }
                    }

                    .active {
                        background: var(--branding-red-tint-200, #EE4950);

                    }
                }
            }

            .bottom {

                display: flex;
                padding: 0px 24px;
                align-items: center;
                gap: 12px;
                flex-shrink: 0;
                align-self: stretch;
                width: 100%;

                box-sizing: border-box;

                div {
                    display: flex;
                    height: 48px;
                    padding: 0px 24px;
                    align-items: center;
                    gap: 10px;
                    flex-shrink: 0;
                    align-self: stretch;
                    border-radius: 8px;
                    cursor: pointer;

                    .logout {
                        color: #FFF;
                        font-family: Inter;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }
                }
            }
        }
        .sidebar{
            display: none;
        }
        .content {
            flex: 1;
            width: 100%;
            // background-color: rebeccapurple;
            .mobileNavbar {
                background: transparent;
                width: 100%;
                padding: 10px;
                padding-bottom: 26.5px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: space-between;
                align-self: stretch;
                height: fit-content;
                position: relative;

                .left {
                    h1 {
                        color: var(--neutral-1000-night, #FAFAFA);
                        font-family: Inter;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        padding: 10px 0;
                        margin: 0;
                    }
                }

                .right {
                    display: flex;
                    gap: 5px;
                    height: 100%;

                    hr {
                        width: 0.998px;
                        align-self: stretch;
                        background: #252525;
                        height: 50px;
                    }
                }


            }
            .navbar{
                display: none;
            }

            .children {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                padding: 16px;
                box-sizing: border-box;
                // background: red;
                overflow: hidden;
            }

            hr {
                height: 1px;
                flex-shrink: 0;
                width: 100%;
                margin: 0;
                border: none;
                background: #252525;
            }
        }

        .selector {
            display: flex;
            padding: 6px 5px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            cursor: pointer;
            position: relative;

            .selected {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;

                .name {
                    width: 50px;
                    color: #FFF;
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    /* 120% */
                }
            }

            .dropdown {
                position: absolute;
                bottom: -180px;
                left: -5px;
                display: none;
                flex-direction: column;
                width: 130px;
                align-items: flex-start;
                border-radius: 0px 0px 8px 8px;
                border-right: 1px solid #252525;
                border-bottom: 1px solid #252525;
                border-left: 1px solid #252525;
                background: #1B1A1A;
                box-sizing: border-box;
                z-index: 10;
                /* Shadow/lg */
                box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);

                &:hover {
                    display: flex;
                }

                div {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 12px;
                    flex: 1 0 0;

                    padding: 10px 14px;

                    align-self: stretch;
                    color: #FFF;
                    font-family: Inter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 24px;

                    /* 150% */
                    span {
                        display: flex;
                        align-items: center;
                        gap: 12px;
                    }

                    &:hover {
                        background: #252525;
                    }

                    img:nth-child(2) {
                        display: none;
                    }
                }

                .active {
                    background: #EE4950;

                    img:nth-child(2) {
                        display: block;
                    }
                }
            }
        }

        .profile {
            display: flex;
            align-items: center;
            gap: 12px;
            cursor: pointer;

            .name {
                color: #FFF;
                text-align: right;
                font-family: Inter;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            img {
                width: 30px;
                height: 30px;
                border-radius: 48px;
                border: 2px solid #EE4950;
            }
        }
    }
}
.checkbox {
    text-wrap: nowrap;
    margin-bottom: 30px;
    display: flex;
    gap: 13px;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    input[type="checkbox"] {
        cursor: pointer;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        border: 1.5px solid var(--gray-dark-4, #7C7C8D);
        // background: #a02a2a;
        accent-color: transparent;
        appearance: none;
        
    }
    input[type="checkbox"]:checked {
        appearance: none;
        border-radius: 4px;
        border: 1.5px solid #EE4950;
        &::before{
            content: "✔";
            color: #EE4950;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            font-weight: 700;
        }
        
    }

    label {
        color: var(--primary-white-1, #E0E4E7);

        /* tittle/16 M */
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}
.new_videoThumbnails {
  margin: 20px;
  padding: 20px;
  color: white;
}

.new_gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-items: center;
}

.new_videoThumbnail {
  background-color: #2c2c2c;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  width: 250px; /* Ensuring all video thumbnails have the same width */
  height: 200px; /* Ensuring all video thumbnails have the same height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.new_videoThumbnail:hover {
  transform: scale(1.05);
  background-color: #3c3c3c;
}

.new_thumbnail {
  width: 100%; /* Make the thumbnail image fill the container width */
  height: 150px; /* Ensuring the thumbnail height is consistent */
  object-fit: cover; /* Ensure the image fits nicely inside the container */
  border-radius: 4px;
  cursor: pointer;
}

.new_videoTitle {
  margin-top: 10px;
  font-size: 16px;
  color: #ffffff;
}

.new_noData {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.new_newVideoPlayer {
  margin-top: 30px;
  text-align: center;
}

.new_newVideoPlayer button {
  margin-bottom: 10px;
  padding: 8px 16px;
  background-color: #ff5c5c;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.new_newVideoPlayer button:hover {
  background-color: #ff1a1a;
}

.new_videoPlayer {
  width: 100%;
  max-width: 700px;
  height: auto;
  border-radius: 8px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .new_gridContainer {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .new_thumbnail {
    height: 120px; /* Adjust for smaller screens */
  }

  .new_videoPlayer {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .new_gridContainer {
    grid-template-columns: 1fr;
  }

  .new_thumbnail {
    width: 100%;
    height: 100px;
  }
}

@import "../../variables.scss";
@media screen and (min-width: $breakpoint-tablet) {
    .grid{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 20px;
    }
    .card{
        display: flex;
        flex-direction: column;
        gap: 7px;
        align-self: stretch;
        box-sizing: border-box;
        width: fit-content;
        cursor: pointer;
        // border: 1px solid #626A66;
        border-radius: 7.63px;
        padding: 10px;
        box-sizing: border-box;
        background-color: #1B1A1A;
        box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
        // width: ;
        .title{
            padding: 0;
            color: #fff;
            // text-align: center;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            margin: 0;
            width: 200px;
            text-wrap: nowrap;
            
            text-overflow:ellipsis;
            overflow: hidden;
        }
        .description{
            padding: 0;
            margin: 0;
            color: #ADB0B2;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
        .thumbnail{
            width: 240px;
            height: 140px;
            object-fit: cover;
            border-radius: 7.63px;
            cursor: pointer;
            box-sizing: border-box;
    
        }
        position: relative;
        .checkbox{
            position: absolute;
            top:10;
            background-color: transparent;
            z-index: 10;
            width: 20px;
            height: 20px;
            border-radius: 4px;
            border: 1.5px solid var(--gray-dark-4, #7C7C8D);
            // background: #a02a2a;
            accent-color: transparent;
            appearance: none;
        }
        .checkbox:checked {
            appearance: none;
            border-radius: 4px;
            border: 1.5px solid #EE4950;
            &::before{
                content: "✔";
                color: #EE4950;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                font-weight: 700;
            }
            
        }
    }
    .card:hover{
        border: 2px solid #EE4950;
        padding:8px;
    }
    .noData {
        display: flex;
        justify-content: center;
        align-items: center;
    
        flex-direction: column;
        height: 100%;
        width: 100%;
        color: var(--neutral-400-night, #626A66);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        cursor: default;
    
        img {
            width: 100px;
            height: 100px;
        }
    
    }
    .overlay{
        position: absolute;
        bottom: 10px;
        z-index: 100;
        width: calc(100% - 400px);
        .actionModal{
            background-color: #EE4950;
            color: white;
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            height: 40px;
            padding: 10px;
            border-radius:7.63px;
            font-family: Inter;
            p{
                font-size: 18px;
            }
            .action{
                display: flex;
                align-items: center;
                gap: 10px;
                span{
                    cursor: pointer;
                    font-size: 18px;
                }
            }
        }
    }
}
@media screen and (min-width: $breakpoint-mobile) and (max-width: $breakpoint-tablet) {
    .grid{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
        gap: 20px;
        // background-color: #626A66;
    }
    .card{
        display: flex;
        flex-direction: column;
        gap: 7px;
        align-self: stretch;
        box-sizing: border-box;
        width: fit-content;
        cursor: pointer;
        // border: 1px solid #626A66;
        border-radius: 7.63px;
        padding: 10px;
        box-sizing: border-box;
        background-color: #1B1A1A;
        box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
        // width: ;
        .title{
            padding: 0;
            color: #fff;
            // text-align: center;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            margin: 0;
            width: 200px;
            text-wrap: nowrap;
            
            text-overflow:ellipsis;
            overflow: hidden;
        }
        .description{
            padding: 0;
            margin: 0;
            color: #ADB0B2;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
        .thumbnail{
            width: 220px;
            height: 120px;
            object-fit: cover;
            border-radius: 7.63px;
            cursor: pointer;
            box-sizing: border-box;
    
        }
        position: relative;
        .checkbox{
            position: absolute;
            top:10;
            background-color: transparent;
            z-index: 10;
            width: 20px;
            height: 20px;
            border-radius: 4px;
            border: 1.5px solid var(--gray-dark-4, #7C7C8D);
            // background: #a02a2a;
            accent-color: transparent;
            appearance: none;
        }
        .checkbox:checked {
            appearance: none;
            border-radius: 4px;
            border: 1.5px solid #EE4950;
            &::before{
                content: "✔";
                color: #EE4950;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                font-weight: 700;
            }
            
        }
    }
    .card:hover{
        border: 2px solid #EE4950;
        padding:8px;
    }
    .noData {
        display: flex;
        justify-content: center;
        align-items: center;
    
        flex-direction: column;
        height: 100%;
        width: 100%;
        color: var(--neutral-400-night, #626A66);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        cursor: default;
    
        img {
            width: 100px;
            height: 100px;
        }
    
    }
    .overlay{
        position: absolute;
        bottom: 10px;
        z-index: 100;
        width: calc(100% - 400px);
        .actionModal{
            background-color: #EE4950;
            color: white;
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            height: 40px;
            padding: 10px;
            border-radius:7.63px;
            font-family: Inter;
            p{
                font-size: 18px;
            }
            .action{
                display: flex;
                align-items: center;
                gap: 10px;
                span{
                    cursor: pointer;
                    font-size: 18px;
                }
            }
        }
    }
}
@media screen and (max-width: $breakpoint-mobile) {
    .grid{
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
        // background-color: #626A66;
    }
    .card{
        display: flex;
        flex-direction: column;
        gap: 7px;
        align-self: stretch;
        box-sizing: border-box;
        border: 1px solid #626A66;
        border-radius: 7.63px;
        padding: 10px;
        box-sizing: border-box;
        // width: ;
        // background-color: red;
        cursor: pointer;
        .title{
            padding: 0;
            color: #fff;
            // text-align: center;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            margin: 0;
            width: 200px;
            text-wrap: nowrap;
            
            text-overflow:ellipsis;
            overflow: hidden;
        }
        .description{
            padding: 0;
            margin: 0;
            color: #ADB0B2;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
        .thumbnail{
            width:100%;
            height: 170px;
            object-fit: cover;
            border-radius: 7.63px;
            cursor: pointer;
            box-sizing: border-box;
    
        }
        position: relative;
        .checkbox{
            position: absolute;
            top:10;
            background-color: transparent;
            z-index: 10;
            width: 20px;
            height: 20px;
            border-radius: 4px;
            border: 1.5px solid var(--gray-dark-4, #7C7C8D);
            // background: #a02a2a;
            accent-color: transparent;
            appearance: none;
        }
        .checkbox:checked {
            appearance: none;
            border-radius: 4px;
            border: 1.5px solid #EE4950;
            &::before{
                content: "✔";
                color: #EE4950;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                font-weight: 700;
            }
            
        }
    }
    .card:hover{
        border: 2px solid #EE4950;
        padding:8px;
    }
    .noData {
        display: flex;
        justify-content: center;
        align-items: center;
    
        flex-direction: column;
        height: 100%;
        width: 100%;
        color: var(--neutral-400-night, #626A66);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        cursor: default;
    
        img {
            width: 100px;
            height: 100px;
        }
    
    }
    .overlay{
        position: absolute;
        bottom: 10px;
        z-index: 100;
        width: calc(100% - 400px);
        .actionModal{
            background-color: #EE4950;
            color: white;
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            height: 40px;
            padding: 10px;
            border-radius:7.63px;
            font-family: Inter;
            p{
                font-size: 18px;
            }
            .action{
                display: flex;
                align-items: center;
                gap: 10px;
                span{
                    cursor: pointer;
                    font-size: 18px;
                }
            }
        }
    }
}
@import "../../variables.scss";

@media screen and (max-width: $breakpoint-mobile) {
    thead {
        .head {
            font-size: 15px !important;
        }

    }

    tbody {
        .title {
            font-size: 11px;
        }

        .date {
            font-size: 11px;
        }

        .action {
            img {
                width: 14px;
                height: 14px;
            }
        }
    }
}

@media screen and (min-width: $breakpoint-mobile) and (max-width: $breakpoint-tablet) {
    thead {
        .head {
            font-size: 17px !important;
        }

    }

    tbody {
        .title {
            font-size: 14px;

        }

        .date {
            font-size: 14px;
        }

        .action {
            img {
                width: 18px;
                height: 18px;
            }
        }
    }
}

// for larger screeens leave it as it is
.table {
    width: 100%;
    border-collapse: collapse;
    font-family: Inter;


    thead {
        margin-bottom: 100px;

        .head {
            color: #AFB6B2;
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-align: left;
            padding: 10px;
        }

        tr th {
            // border-bottom: 1px solid var(--neutral-200-night, #313533);

            &:first-child {
                display: flex;
                justify-content: center;
                align-items: center;
            }

        }

        .header {
            display: flex;
            gap: 5px;

            .sorting {
                cursor: pointer;
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                width: fit-content;

                button {
                    background: transparent;
                    border: none;
                    color: white;
                    cursor: pointer;
                    // padding: 0;
                    font-size: 9px;
                }

            }
        }
    }

    .line {

        border-bottom: 1px solid var(--neutral-200-night, #313533);
    }




    tbody {
        position: relative;

        tr {
            // cursor: pointer;

            td {
                padding: 9.538px 7.63px 9.538px 9.538px;
                box-sizing: border-box;
            }

            .no {
                color: var(--neutral-400-night, #626A66);
                font-family: Inter;
                font-size: 15.26px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                display: flex;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
            }

            .title {
                color: var(--neutral-800-night, #AFB6B2);
                font-family: Inter;
                font-size: 15.26px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                display: flex;
                gap: 24px;
                align-items: center;
            }

            .participents_container {
                display: flex;
                padding: 8px 0px;
                flex-direction: row;
                // justify-content: center;
                align-items: flex-start;
                align-self: stretch;

                .img_first {
                    border-radius: 64px;
                    border: 2px solid var(--neutral-light-n-0, #fff);
                    cursor: pointer;
                    background-color: gray;
                }

                .img {
                    border-radius: 64px;
                    position: relative;
                    border: 2px solid var(--neutral-light-n-0, #fff);
                    cursor: pointer;
                    background-color: gray;
                }

                .more_icon {
                    border-radius: 64px;
                    position: relative;
                    cursor: pointer;
                    font-size: smaller;
                    color: #fff;
                    background-color: rgb(202, 202, 202);
                    width: 30px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    left: -30px;
                    top: 0.5px;
                }
            }

            .date {
                color: var(--neutral-600-night, #7A8580);
                font-family: Inter;
                font-size: 15.26px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .action {
                display: flex;
                gap: 5px;

                img {
                    cursor: pointer;
                    width: 20px;

                    &:hover {
                        transform: scale(0.9);
                        transition: transform 0.3s ease-in-out;
                    }
                }
            }

            .active {
                color: #EE4950 !important;
            }

        }
    }

}

.noData {
    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column;
    height: 100%;
    width: 100%;
    color: var(--neutral-400-night, #626A66);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: default;

    img {
        width: 100px;
        height: 100px;
    }

}
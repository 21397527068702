@import "../../variables.scss";
@media screen and (min-width: $breakpoint-mobile) {
    .pagination {
        hr {
            width: 100%;
            margin: 0;
            padding: 0;
        }
    
        .totalPages {
            display: flex;
            padding: 12px 24px 0px 24px;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
    
            .disabled {
                display: flex;
                padding: 8px 14px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 8px;
                opacity: 0.3;
                background: rgba(238, 73, 80, 0.20);
                /* Shadow/xs */
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                border: none;
                cursor: pointer;
            }
    
            .nextBtn,
            .prevBtn {
                display: flex;
                padding: 8px 14px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 8px;
                background: rgba(238, 73, 80, 0.20);
    
                /* Shadow/xs */
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                border: none;
                cursor: pointer;
            }
    
            .pageNumbers {
                display: flex;
            
                button {
                    display: flex;
                    min-width: 40px;
                    height: 40px;
                    justify-content: center;
                    align-items: center;
                    background: transparent;
                    border: none;
                    border-radius: 8px;
                    color: #7A8580;
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    /* 142.857% */
                    cursor: pointer;
                }
    
                .active {
                    color: #EE4950;
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    /* 142.857% */
                }
    
                .skip {
                    color: #7A8580;
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    /* 142.857% */
                    display: flex;
                    width: 40px;
                    padding: 12px;
                    justify-content: center;
                    align-items: center;
                    flex-shrink: 0;
                    align-self: stretch;
                }
            }
        }
    
    }
}
@media screen and (max-width: $breakpoint-mobile) {
    .pagination {
        hr {
            width: 100%;
            margin: 0;
            padding: 0;
        }
        .leftArrow, .rightArrow {
            width: 10px;
        }
        .totalPages {
            display: flex;
            padding: 12px 0px 0px 0px;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
    
            .disabled {
                display: flex;
                padding: 8px 14px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 8px;
                opacity: 0.3;
                background: rgba(238, 73, 80, 0.20);
                /* Shadow/xs */
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                border: none;
                cursor: pointer;
            }
    
            .nextBtn,
            .prevBtn {
                display: flex;
                padding: 8px 14px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 8px;
                background: rgba(238, 73, 80, 0.20);
    
                /* Shadow/xs */
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                border: none;
                cursor: pointer;
                
            }
    
            .pageNumbers {
                display: flex;
            
                button {
                    display: flex;
                    min-width: 40px;
                    height: 40px;
                    justify-content: center;
                    align-items: center;
                    background: transparent;
                    border: none;
                    border-radius: 8px;
                    color: #7A8580;
                    font-family: Inter;
                    font-size: 9px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    /* 142.857% */
                    cursor: pointer;
                }
    
                .active {
                    color: #EE4950;
                    font-family: Inter;
                    font-size: 9px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    /* 142.857% */
                }
    
                .skip {
                    color: #7A8580;
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    /* 142.857% */
                    display: flex;
                    width: 40px;
                    padding: 12px;
                    justify-content: center;
                    align-items: center;
                    flex-shrink: 0;
                    align-self: stretch;
                }
            }
        }
    
    }
}
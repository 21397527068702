@import "../../variables.scss";
@import "../../common";

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.composeModalContainer {
    position: relative;
    width: 830px;
    display: flex;
    // height: 700px;
    height: calc(100vh - 100px);
    justify-content: start;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    background-color: #1B1A1A;
    color: $primary-text-color;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19);
    font-family: $font-family-base;

    .modalHeader {

        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        padding-inline: 20px;
        box-sizing: border-box;


        // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        // border-bottom: 1px solid  #373737;

        .title {
            font-family: $font-family-base;
            font-size: 1.8rem;

        }

        @media screen and (max-width: 768px) {
            .title {
                font-size: 1.5rem;
            }
        }

        .close {
            cursor: pointer;

            img {
                width: 20px;
                height: 20px;
            }

            padding: 4px;
        }
    }

    .modalBody {
        width: 100%;
        height: 70vh;
        height: calc(100vh - 231px);
        display: flex;
        flex-direction: column;
        gap: 14px;
        position: relative;
        overflow-y: auto;
        @include scrollbar-style;
        padding-inline: 20px;
        box-sizing: border-box;

        .usersSearchInputContainer {

            .focus {
                border: 1px solid $primary-color;

            }

            .input {
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-self: stretch;
                border-radius: 10px;
                border: 1px solid #373737;
                // padding-top: 14px;
                padding-inline: 6px;

                .tagsContainer {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    gap: 13px;
                    margin-top: 10px;
                    border-radius: 12px;
                    padding: 4px;
                    max-width: 100%;

                    // overflow-x: auto;

                    .tags {
                        background-color: silver;
                        color: #1B1A1A;
                        border-radius: 12px;
                        padding: 4px;
                        padding-inline: 12px;
                        position: relative;

                        .tagClose {
                            width: 14px;
                            height: auto;
                            padding: 4px;
                            position: absolute;
                            top: -8px;
                            background-color: $primary-color;
                            border-radius: 100%;
                            right: -20px;
                        }
                    }

                    .viewAllLess {
                        cursor: pointer;
                        color: $primary-color;
                        font-size: 14px;
                        font-weight: 500;
                        text-decoration: underline;
                    }
                }

                input {
                    color: var(--primary-white-1, #E0E4E7);

                    /* tittle/16 R */
                    font-family: "Inter";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    background-color: transparent;
                    border: none;
                    width: 100%;
                    height: 100%;
                    padding: 15px 10px 15px 10px;
                    box-sizing: border-box;
                    border-radius: inherit;
                    outline: none;


                }


                img {
                    width: 20px;
                    height: 20px;
                    object-fit: cover;
                    margin-right: 10px;
                    cursor: pointer;
                }
            }

            .users_dropdown_list {
                position: relative;
                border-radius: 4px;
                @include scrollbar-style;
                overflow-y: auto;
                max-height: 250px;
                box-shadow: 0px 1px 2px rgba(107, 107, 107, 0.05);
                margin-bottom: 10px;
                border: 1px solid $border-color;
                border-top: none;

                .show_more_container {
                    padding-block: 10px;
                }

                .selectUnselectContainer {
                    background: $modal-background-color;
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    position: sticky;
                    top: 0;

                    .select_all {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-end;
                        gap: 8px;
                        align-self: stretch;
                        padding: 10px 18px 10px 0px;
                        box-sizing: border-box;

                        cursor: pointer;
                        color: $primary-color;
                        text-align: center;

                        font-family: $font-family-base;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 140%;
                    }
                }

                .selected_row {
                    background: var(--colors-brand-bg, #3e3e3e);
                }

                .user_cotainer {
                    display: flex;
                    padding: 0px 16px;
                    justify-content: space-between;
                    align-items: center;
                    gap: 16px;
                    align-self: stretch;
                    border-radius: 4px;
                    // border-bottom: 1px solid $primary-color;
                    cursor: pointer;

                    .left {
                        display: flex;
                        align-items: center;
                        gap: 12px;

                        .avatar {
                            cursor: pointer;
                            width: 40px;
                            height: 40px;
                            position: relative;
                            border-radius: 64px;
                            transition: transform 0.2s ease-in-out;

                            &:hover {
                                transform: scale(1.1);
                            }
                        }

                        .name_container {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;

                            .name {
                                color: $primary-text-color;

                                font-family: $font-family-base;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 140%;
                                text-transform: capitalize;
                            }

                            .sub_text {
                                color: $primary-text-color;

                                font-family: $font-family-base;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 140%;
                            }
                        }
                    }

                    .right {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        button {
                            display: flex;
                            height: 44px;
                            padding: 6px 12px;
                            justify-content: center;
                            align-items: center;
                            border-radius: 4px;
                            background: var(--colors-style-neutrals-n-20, #F5F6F7);

                            span {
                                color: var(--colors-style-key-colors-blue, #0057D9);
                                text-align: center;
                                font-family: Plus Jakarta Sans;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 140%;
                            }
                        }
                    }
                }

                .show_more_container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .editorContainer {
            position: relative;
            height: 100%;

        }
    }

    .modalFooter {

        width: 100%;
        display: flex;

        justify-content: space-between;
        // margin-top: 13px;
        padding: 20px;
        // padding-block: 10px;
        box-sizing: border-box;

        // box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
        // border-top: 1px solid  #373737;
    }
}

.error {
    display: flex;
    align-items: center;
    font-family: $font-family-base;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #EB5757;
    padding: 0;
    margin: 0;
}
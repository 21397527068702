@import "../../variables.scss";
@media screen and (min-width: $breakpoint-mobile) {
    .legalNotice {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 19.075px;
        flex: 1 0 0;
        align-self: stretch;
        color: white;
        font-family: Inter;

        .responsibility {
            color: gray;
        }

        .heading {
            font-size: 22.89px;
        }

        .descrition {
            font-size: 14px;
        }

        .subHeading {
            font-size: 15px;
            font-weight: normal;
        }

        ol.bullets {
            list-style: lower-alpha;
            font-size: 15px;
        }
    }

}

@media screen and (max-width: $breakpoint-mobile) {
    .legalNotice {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 19.075px;
        flex: 1 0 0;
        align-self: stretch;
        width: 100%;
        color: white;
        font-family: Inter;
        // background-color: aqua;
        // overflow: scroll;
        box-sizing: border-box;

        .section {
            // width: 75%;
            text-wrap: wrap;
            // background-color: red;
            box-sizing: border-box;
        }

        .responsibility {
            color: gray;
        }

        .heading {
            font-size: 14px;
        }

        .description {
            font-size: 14px;
            word-wrap: break-word;
            /* This is to break long words */
            overflow-wrap: break-word;
            /* This is to break long words */
            word-break: break-all;
            /* This is to ensure words break correctly */
            box-sizing: border-box;
            width: 100%;
            /* Ensure the container takes the full width of its parent */
            max-width: 100%;
            /* Prevent the container from exceeding the width */

        }

        .subHeading {
            font-size: 15px;
            font-weight: normal;
        }

        ol.bullets {
            list-style: lower-alpha;
            font-size: 15px;
            box-sizing: border-box;
        }
    }
}
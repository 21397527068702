@import "../../variables.scss";
@media screen and (min-width: $breakpoint-tablet) {
    .table {
        width: 100%;
        border-collapse: collapse;

        .head {
            color: #AFB6B2;
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-align: left;
            padding: 10px;
        }

        thead {
            margin-bottom: 100px;

            tr th {
                // border-bottom: 1px solid var(--neutral-200-night, #313533);
                
                &:first-child {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                
            }
            .header{
                display: flex;
                gap: 5px;
                .sorting{
                    cursor: pointer;
                    display: inline-flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    // background-color: #03E795;
                    // background-color: #03E795;
                    width: fit-content;
                    button{
                        background: transparent;
                        border:none;
                        color:white;
                        cursor: pointer;
                        // padding: 0;
                        font-size: 9px;
                    }
                    
                }
            }
        }
        
        .line {
            
            border-bottom: 1px solid var(--neutral-200-night, #313533);
        }

        .no {
            color: var(--neutral-400-night, #626A66);
            font-family: Inter;
            font-size: 15.26px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
        }

        .title {
            color: var(--neutral-800-night, #AFB6B2);
            font-family: Inter;
            font-size: 15.26px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            display: flex;
            gap: 24px;
            align-items: center;
        }

        .artist {
            color: var(--neutral-600-night, #7A8580);
            font-family: Inter;
            font-size: 15.26px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .genre {
            color: var(--neutral-600-night, #7A8580);
            font-family: Inter;
            font-size: 15.26px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .action {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .active {
            color: #EE4950 !important;
        }
        .views, .downloads{
            color: blue;
            font-family: Inter;
            font-size: 15.26px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            width: 100%;
            display: flex;
            justify-content: center;
        }
        .uploadedOn{
            color: var(--neutral-600-night, #7A8580);
            font-family: Inter;
            font-size: 15.26px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        tbody {
            position: relative;

            tr {
                cursor: pointer;

                td {
                    padding: 9.538px 7.63px 9.538px 9.538px;
                    box-sizing: border-box;
                }

            }

            .dropdown {
                position: absolute;
                // display: flex;
                right: 150px;
                top: 10px;
                width: 190px;
                padding: 10px 14px;
                align-items: center;
                gap: 8px;
                border-radius: 4px;
                background: #373737;
                box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.08);
                color: #FFF !important;
                font-family: Inter;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                /* 160% */
                display: none;
            }

        }


    }

    .adsTable {
        width: 100%;
        border-collapse: collapse;
        .head {
            color: #AFB6B2;
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-align: left;
            padding: 10px;
            box-sizing: border-box;
            height: 100%;
            // background: red;
        }

        thead {
            margin-bottom: 100px;

            tr th {
                border-bottom: 1px solid var(--neutral-200-night, #313533);
                &:first-child {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .line {
            border-bottom: 1px solid var(--neutral-200-night, #313533);
        }

        .no {
            color: var(--neutral-400-night, #626A66);
            font-family: Inter;
            font-size: 15.26px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            
        }

        .title {
            display: flex;
            align-items: center;
            gap: 24px;
            flex: 1 0 0;

            img {
                width: 64px;
                height: 64px;
                border-radius: 2px;
                object-fit: cover;
            }

            .text {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 16px;
                flex: 1 0 0;
                align-self: stretch;

                h1 {
                    color: var(--neutral-800-night, #AFB6B2);
                    font-family: Inter;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin: 0;
                }

                p {
                    color: var(--neutral-800-night, #AFB6B2);
                    font-family: Inter;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin: 0;
                }
            }
        }

        .date {
            color: var(--neutral-600-night, #7A8580);
            font-family: Inter;
            font-size: 15.26px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 0 auto;
        }

        .status {

            font-family: Inter;
            font-size: 15.26px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .green {
            color: #03E795;
        }

        .red {
            color: #FF3740;
        }

        .action {

            display: flex;
            gap: 32px;
            align-items: center;
            justify-content: center;
            img {
                cursor: pointer;
            }
        }

        .active {
            color: #EE4950 !important;
        }

        tbody {
            position: relative;

            tr {
                cursor: default;

                td {
                    padding: 9.538px 7.63px 9.538px 9.538px;
                }

            }
        }


    }

    .noData {
        display: flex;
        justify-content: center;
        align-items: center;

        flex-direction: column;
        height: 100%;
        width: 100%;
        color: var(--neutral-400-night, #626A66);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        cursor: default;

        img {
            width: 100px;
            height: 100px;
        }

    }
}
@media screen and (min-width: $breakpoint-mobile) and (max-width: $breakpoint-tablet) {
    .table {
        width: 100%;
        border-collapse: collapse;

        .head {
            color: #AFB6B2;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-align: left;
            padding: 10px;
        }

        thead {
            margin-bottom: 100px;

            tr th {
                // border-bottom: 1px solid var(--neutral-200-night, #313533);
                
                &:first-child {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                
            }
            .header{
                display: flex;
                gap: 5px;
                .sorting{
                    cursor: pointer;
                    display: inline-flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    // background-color: #03E795;
                    // background-color: #03E795;
                    width: fit-content;
                    button{
                        background: transparent;
                        border:none;
                        color:white;
                        cursor: pointer;
                        // padding: 0;
                        font-size: 9px;
                    }
                    
                }
            }
        }
        
        .line {
            
            border-bottom: 1px solid var(--neutral-200-night, #313533);
        }

        .no {
            color: var(--neutral-400-night, #626A66);
            font-family: Inter;
            font-size: 13.26px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
        }

        .title {
            color: var(--neutral-800-night, #AFB6B2);
            font-family: Inter;
            font-size: 13.26px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            display: flex;
            gap: 24px;
            align-items: center;
        }

        .artist {
            color: var(--neutral-600-night, #7A8580);
            font-family: Inter;
            font-size: 13.26px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .genre {
            color: var(--neutral-600-night, #7A8580);
            font-family: Inter;
            font-size: 13.26px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .action {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .active {
            color: #EE4950 !important;
        }
        .views, .downloads{
            color: blue;
            font-family: Inter;
            font-size: 13.26px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            width: 100%;
            display: flex;
            justify-content: center;
        }
        .uploadedOn{
            color: var(--neutral-600-night, #7A8580);
            font-family: Inter;
            font-size: 13.26px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        tbody {
            position: relative;

            tr {
                cursor: pointer;

                td {
                    padding: 9.538px 7.63px 9.538px 9.538px;
                    box-sizing: border-box;
                }

            }

            .dropdown {
                position: absolute;
                // display: flex;
                right: 150px;
                top: 10px;
                width: 190px;
                padding: 10px 14px;
                align-items: center;
                gap: 8px;
                border-radius: 4px;
                background: #373737;
                box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.08);
                color: #FFF !important;
                font-family: Inter;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                /* 160% */
                display: none;
            }

        }


    }

    .adsTable {
        width: 100%;
        border-collapse: collapse;
        .head {
            color: #AFB6B2;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-align: left;
            padding: 8px;
            box-sizing: border-box;
            height: 100%;
            // background: red;
        }

        thead {
            margin-bottom: 100px;

            tr th {
                border-bottom: 1px solid var(--neutral-200-night, #313533);
                &:first-child {
                    // display: flex;
                    // justify-content: center;
                    // align-items: center;
                    padding: 0;
                    margin: 0;
                    border:none
                }
            }
        }

        .line {
            border-bottom: 1px solid var(--neutral-200-night, #313533);
        }

        .no {
            color: var(--neutral-400-night, #626A66);
            font-family: Inter;
            font-size: 13.26px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            
        }

        .title {
            display: flex;
            align-items: center;
            gap: 12px;
            flex: 1 0 0;

            img {
                width: 64px;
                height: 64px;
                border-radius: 2px;
                object-fit: cover;
            }

            .text {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 16px;
                flex: 1 0 0;
                align-self: stretch;

                h1 {
                    color: var(--neutral-800-night, #AFB6B2);
                    font-family: Inter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin: 0;
                }

                p {
                    color: var(--neutral-800-night, #AFB6B2);
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin: 0;
                }
            }
        }

        .date {
            color: var(--neutral-600-night, #7A8580);
            font-family: Inter;
            font-size: 12.26px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 0 auto;
        }

        .status {

            font-family: Inter;
            font-size: 13.26px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .green {
            color: #03E795;
        }

        .red {
            color: #FF3740;
        }

        .action {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 16px;

            img {
                cursor: pointer;
                width: 20px;
            }
        }

        .active {
            color: #EE4950 !important;
        }

        tbody {
            position: relative;

            tr {
                cursor: default;

                td {
                    padding: 9.538px 7.63px 9.538px 9.538px;
                }

            }
        }


    }

    .noData {
        display: flex;
        justify-content: center;
        align-items: center;

        flex-direction: column;
        height: 100%;
        width: 100%;
        color: var(--neutral-400-night, #626A66);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        cursor: default;

        img {
            width: 100px;
            height: 100px;
        }

    }
}
@media screen and (max-width: $breakpoint-mobile) {
    .table {
        width: 100%;
        border-collapse: collapse;

        .head {
            color: #AFB6B2;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-align: left;
            padding: 10px;
        }

        thead {
            margin-bottom: 100px;

            tr th {
                // border-bottom: 1px solid var(--neutral-200-night, #313533);

                &:first-child {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .header{
                display: flex;
                gap: 5px;
                .sorting{
                    cursor: pointer;
                    display: inline-flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    // background-color: #03E795;
                    // background-color: #03E795;
                    width: fit-content;
                    button{
                        background: transparent;
                        border:none;
                        color:white;
                        cursor: pointer;
                        // padding: 0;
                        font-size: 9px;
                    }
                    
                }
            }
        }

        .line {
            border-bottom: 1px solid var(--neutral-200-night, #313533);
        }

        .no {
            color: var(--neutral-400-night, #626A66);
            font-family: Inter;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
        }

        .title {
            color: var(--neutral-800-night, #AFB6B2);
            font-family: Inter;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            display: flex;
            gap: 24px;
            align-items: center;
            img{
                width: 20px;
            }
        }

        .artist {
            color: var(--neutral-600-night, #7A8580);
            font-family: Inter;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .genre {
            color: var(--neutral-600-night, #7A8580);
            font-family: Inter;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .action {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                width: 20px;
            }
        }

        .active {
            color: #EE4950 !important;
        }
        .views, .downloads{
            color: blue;
            font-family: Inter;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            width: 100%;
            display: flex;
            justify-content: center;
        }
        .uploadedOn{
            color: var(--neutral-600-night, #7A8580);
            font-family: Inter;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        tbody {
            position: relative;

            tr {
                cursor: pointer;

                td {
                    padding: 9.538px 7.63px 9.538px 9.538px;
                    box-sizing: border-box;
                }

            }

            .dropdown {
                position: absolute;
                // display: flex;
                right: 150px;
                top: 10px;
                width: 190px;
                padding: 10px 14px;
                align-items: center;
                gap: 8px;
                border-radius: 4px;
                background: #373737;
                box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.08);
                color: #FFF !important;
                font-family: Inter;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                /* 160% */
                display: none;
            }

        }


    }

    .adsTable {
        width: 100%;
        border-collapse: collapse;

        .head {
            color: #AFB6B2;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-align: left;
            padding: 10px;
        }

        thead {
            margin-bottom: 100px;

            tr th {
                border-bottom: 1px solid var(--neutral-200-night, #313533);

                &:first-child {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .line {
            border-bottom: 1px solid var(--neutral-200-night, #313533);
        }

        .no {
            color: var(--neutral-400-night, #626A66);
            font-family: Inter;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .title {
            display: flex;
            align-items: center;
            gap: 24px;
            flex: 1 0 0;

            img {
                width: 32px;
                height: 32px;
                border-radius: 2px;
                object-fit: cover;
            }

            .text {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 16px;
                flex: 1 0 0;
                align-self: stretch;

                h1 {
                    color: var(--neutral-800-night, #AFB6B2);
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin: 0;
                }

                p {
                    color: var(--neutral-800-night, #AFB6B2);
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin: 0;
                }
            }
        }

        .date {
            color: var(--neutral-600-night, #7A8580);
            font-family: Inter;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 0 auto;
        }

        .status {

            font-family: Inter;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .green {
            color: #03E795;
        }

        .red {
            color: #FF3740;
        }

        .action {

            gap: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                cursor: pointer;
                width: 20px;
            }
        }

        .active {
            color: #EE4950 !important;
        }

        tbody {
            position: relative;

            tr {
                cursor: default;

                td {
                    padding: 9.538px 7.63px 9.538px 9.538px;
                }

            }
        }


    }

    .noData {
        display: flex;
        justify-content: center;
        align-items: center;

        flex-direction: column;
        height: 100%;
        width: 100%;
        color: var(--neutral-400-night, #626A66);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        cursor: default;

        img {
            width: 100px;
            height: 100px;
        }

    }
}
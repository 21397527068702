@import "../../variables.scss";
@media screen and (min-width: $breakpoint-mobile) {
    .videos{
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 32px;
        // background: red;
        box-sizing: border-box;
        align-self: stretch;
        height: fit-content;
        .sortFilter{
            display: flex;
            justify-content: flex-end;
        }
    }
}

@media screen and (max-width: $breakpoint-mobile) {
    .videos{
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 32px;
        // background: red;
        box-sizing: border-box;
        align-self: stretch;
        height: fit-content;
        .sortFilter{
            display: flex;
            justify-content: flex-end;
        }
    }
}
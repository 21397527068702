@import "../../variables.scss";
@media screen and (min-width: $breakpoint-mobile) {
    .container{
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 32px;
        // background: red;
        box-sizing: border-box;
        align-self: stretch;
        height: fit-content;
        .actionBtn{
            display: flex;
            justify-content: flex-end;
            width: 100%;
            .exportBtn{
                width: fit-content;
                border: none;
                border-radius: 5px;
                background-color: #EE4950;
                color: white;
                font-size: 16px;
                padding: 10px;
                cursor: pointer;
            }
        }
    }
}
@media screen and (max-width: $breakpoint-mobile) {
    .container{
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 32px;
        // background: red;
        box-sizing: border-box;
        align-self: stretch;
        height: fit-content;
        .actionBtn{
            display: flex;
            justify-content: flex-end;
            width: 100%;
            .exportBtn{
                width: fit-content;
                border: none;
                border-radius: 5px;
                background-color: #EE4950;
                color: white;
                font-size: 16px;
                padding: 10px;
                cursor: pointer;
            }
        }
        .table{
            width: 100%;
            overflow: scroll;
            *{
                text-wrap: nowrap;
            }
        }
    }
}
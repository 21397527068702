@import "../../variables.scss";
@media screen and (min-width: $breakpoint-mobile) {
    .uploadMusic {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
        flex: 1 0 0;
        align-self: stretch;
        // background: green;
        width: 100%;
        box-sizing: border-box;
        .table{
            width: 100%;
            // background: blue;
            // overflow: scroll;
        }
    }
}
@media screen and (max-width: $breakpoint-mobile) {
    .uploadMusic {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
        flex: 1 0 0;
        align-self: stretch;
        // background: green;
        width: 100%;
        box-sizing: border-box;
        .table{
            width: 100%;
            // background: blue;
            overflow: scroll;
            *{
                text-wrap: nowrap;
            }
        }
    }
}
@import "../../../styles/variables.scss";
@media screen and (min-width: $breakpoint-mobile) {
    .ads {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        flex-shrink: 0;
        align-self: stretch;
        .btn{
            width: 100%;
            display: flex;
            justify-content: flex-end;
            // background-color: gold;
        }
        .mobileBtn{
            display: none;
        }
        .table{
            width:100%;
        }
    }
}
@media screen and (max-width: $breakpoint-mobile) {
    .ads {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        flex-shrink: 0;
        align-self: stretch;
        .btn{
            width: 100%;
            display: none;
            justify-content: flex-end;
            // background-color: gold;

        }
        .mobileBtn{
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }
        .table{
            width: 100%;
            overflow: scroll;
            *{
                text-wrap: nowrap;
            }
        }
    }
}
@import "../../variables.scss";
@media screen and (min-width: $breakpoint-tablet) {
    .container {
        position: relative;
        width: 100%;

        .img {
            width: 100%;

            height: 350px;
            object-fit: cover;
            border-radius: 7.63px;
        }

        .overlay {
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            border-radius: 7.63px;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 50%, #000 100%);

            .content {

                position: relative;
                height: 100%;
                width: 100%;

                .text {
                    position: absolute;
                    bottom: 35px;
                    left: 35px;
                    display: flex;

                    flex-direction: column;
                    align-items: flex-start;
                    gap: 12px;

                    .header {
                        display: flex;

                        align-items: flex-end;
                        gap: 10px;
                        align-self: stretch;
                        align-items: center;

                        h2 {
                            color: var(--neutral-1000-night, #FAFAFA);
                            font-family: Inter;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            margin: 0;
                        }

                        hr {
                            background: var(--neutral-500-night, #7A8580);
                            border: none;
                            rotate: 90deg;
                            width: 20px;

                        }

                        p {
                            color: #FFF;
                            text-align: justify;
                            font-family: Inter;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: normal;
                            margin: 0;
                        }
                    }

                    .desc {

                        color: #FFF;
                        text-align: justify;
                        font-family: Inter;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                        padding-right: 400px;
                        box-sizing: border-box;
                        margin: 0;
                    }
                }
            }

            .closeBtn {

                position: absolute;
                top: 37px;
                right: 47px;
                display: inline-flex;
                height: 33px;
                padding: 6px 16px 6px 12px;
                align-items: center;
                gap: 8px;
                flex-shrink: 0;
                border-radius: 40px;
                background: rgba(255, 255, 255, 0.20);
                color: #E7E7E7;
                font-family: Inter;
                font-size: 15.26px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                cursor: pointer;
            }

            .dots {

                position: absolute;
                top: 90%;
                right: 47px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                gap: 12px;

                span {
                    width: 10px;
                    height: 10px;
                    border-radius: 12px;
                    background: #787878;
                }

                .active {
                    background: #EE4950;
                }
            }
        }
    }
}
@media screen and (min-width: $breakpoint-mobile) and (max-width: $breakpoint-tablet) {
    .container {
        position: relative;
        width: 100%;

        .img {
            width: 100%;

            height: 350px;
            object-fit: cover;
            border-radius: 7.63px;
        }

        .overlay {
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            border-radius: 7.63px;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 50%, #000 100%);

            .content {

                position: relative;
                height: 100%;
                width: 100%;

                .text {
                    position: absolute;
                    bottom: 35px;
                    left: 35px;
                    display: flex;

                    flex-direction: column;
                    align-items: flex-start;
                    gap: 12px;

                    .header {
                        display: flex;

                        align-items: flex-end;
                        gap: 10px;
                        align-self: stretch;
                        align-items: center;

                        h2 {
                            color: var(--neutral-1000-night, #FAFAFA);
                            font-family: Inter;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            margin: 0;
                        }

                        hr {
                            background: var(--neutral-500-night, #7A8580);
                            border: none;
                            rotate: 90deg;
                            width: 20px;

                        }

                        p {
                            color: #FFF;
                            text-align: justify;
                            font-family: Inter;
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: normal;
                            margin: 0;
                        }
                    }

                    .desc {

                        color: #FFF;
                        text-align: justify;
                        font-family: Inter;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                        padding-right: 100px;
                        box-sizing: border-box;
                        margin: 0;
                    }
                }
            }

            .closeBtn {

                position: absolute;
                top: 37px;
                right: 47px;
                display: inline-flex;
                height: 33px;
                padding: 6px 16px 6px 12px;
                align-items: center;
                gap: 8px;
                flex-shrink: 0;
                border-radius: 40px;
                background: rgba(255, 255, 255, 0.20);
                color: #E7E7E7;
                font-family: Inter;
                font-size: 15.26px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                cursor: pointer;
            }

            .dots {

                position: absolute;
                top: 90%;
                right: 47px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                gap: 12px;

                span {
                    width: 10px;
                    height: 10px;
                    border-radius: 12px;
                    background: #787878;
                }

                .active {
                    background: #EE4950;
                }
            }
        }
    }
}
@media screen and (max-width: $breakpoint-mobile) {
    .container {
        position: relative;
        width: 100%;

        .img {
            width: 100%;

            height: 250px;
            object-fit: cover;
            border-radius: 7.63px;
        }

        .overlay {
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            border-radius: 7.63px;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 50%, #000 100%);

            .content {

                position: relative;
                height: 100%;
                width: 100%;

                .text {
                    position: absolute;
                    bottom: 35px;
                    left: 35px;
                    display: flex;

                    flex-direction: column;
                    align-items: flex-start;
                    gap: 12px;

                    .header {
                        display: flex;

                        align-items: flex-end;
                        gap: 10px;
                        align-self: stretch;
                        align-items: center;

                        h2 {
                            color: var(--neutral-1000-night, #FAFAFA);
                            font-family: Inter;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            margin: 0;
                        }

                        hr {
                            background: var(--neutral-500-night, #7A8580);
                            border: none;
                            rotate: 90deg;
                            width: 20px;

                        }

                        p {
                            color: #FFF;
                            text-align: justify;
                            font-family: Inter;
                            font-size: 10px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: normal;
                            margin: 0;
                        }
                    }

                    .desc {

                        color: #FFF;
                        text-align: justify;
                        font-family: Inter;
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                        padding-right: 40px;
                        box-sizing: border-box;
                        margin: 0;
                    }
                }
            }

            .closeBtn {

                position: absolute;
                top: 37px;
                right: 47px;
                display: inline-flex;
                height: 33px;
                padding: 6px 16px 6px 12px;
                align-items: center;
                gap: 8px;
                flex-shrink: 0;
                border-radius: 40px;
                background: rgba(255, 255, 255, 0.20);
                color: #E7E7E7;
                font-family: Inter;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                cursor: pointer;
            }

            .dots {

                position: absolute;
                top: 90%;
                right: 47px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                gap: 12px;

                span {
                    width: 6px;
                    height: 6px;
                    border-radius: 12px;
                    background: #787878;
                }

                .active {
                    background: #EE4950;
                }
            }
        }
    }
}
@import "../../variables.scss";
@media screen and (min-width: $breakpoint-tablet) {
    .container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 19.075px;
        flex: 1 0 0;
        align-self: stretch;
    
        .heading {
            color: #FFF;
            font-family: Inter;
            font-size: 22.89px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin: 0;
        }
        .table{
            width: 100%;
        }
    }
    
}
@media screen and (min-width: $breakpoint-mobile) and (max-width: $breakpoint-tablet) {
    .container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 19.075px;
        flex: 1 0 0;
        align-self: stretch;
    
        .heading {
            color: #FFF;
            font-family: Inter;
            font-size: 17.89px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin: 0;
        }
        .table{
            width: 100%;
        }
    }
    
}
@media screen and (max-width: $breakpoint-mobile) {
    .container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 19.075px;
        flex: 1 0 0;
        align-self: stretch;
        width: 100%;
        .heading {
            color: #FFF;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin: 0;
        }
        .table{
            width:100%;
            overflow: scroll;
        }
    }
    
}

@import "../../variables.scss";

@media screen and (min-width: $breakpoint-tablet) {
    .container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 19.075px;
        flex: 1 0 0;
        align-self: stretch;
        box-sizing: border-box;

        .content {
            display: flex;
            justify-content: space-between;
            width: 100%;
            .form {
                display: flex;
                flex-direction: column;
                gap: 16px;
                width: 400px;

                .heading {
                    color: #FFF;
                    font-family: Inter;
                    font-size: 22.89px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin: 0;
                }

                .textArea {
                    display: flex;
                    height: 180px;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 12px;
                    align-self: stretch;

                    label {
                        color: var(--gray-white, #FFF);

                        /* tittle/16 M */
                        font-family: "DM Sans";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }

                    textarea {
                        display: flex;
                        padding: 12px 16px;
                        align-items: flex-start;
                        gap: 10px;
                        flex: 1 0 0;
                        align-self: stretch;
                        border-radius: 10px;
                        border: 1px solid #373737;
                        outline: none;
                        background: transparent;


                        color: #C4C4C4;
                        text-align: justify;

                        /* tittle/16 R */
                        font-family: "DM Sans";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }

                    .instruction {
                        align-self: stretch;
                        color: #494C4E;
                        text-align: right;

                        /* tittle/16 R */
                        font-family: "DM Sans";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                    .error {
                        font-family: Inter;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 16px;
                        text-align: left;
                        color: #EB5757;
                        padding: 0;
                        margin: 0;
                    }
                }

                .button {
                    display: flex;
                    // width: 506px;
                    height: 60px;
                    padding: 18px 30px;
                    // flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;

                    cursor: pointer;
                    color: var(--primary-white-1, #E0E4E7);

                    /* tittle/16 M */
                    font-family: "DM Sans";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;

                    border-radius: 8px;
                    border: 1px solid #212121;
                    background: #EE4950;
                }
            }
        }
    }
}
@media screen and (min-width: $breakpoint-mobile) and (max-width: $breakpoint-tablet) {
    .container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 19.075px;
        flex: 1 0 0;
        align-self: stretch;
        box-sizing: border-box;

        .content {
            display: flex;
            justify-content: center;
            width: 100%;
            padding-bottom: 10px;
            .form {
                display: flex;
                flex-direction: column;
                gap: 16px;
                width: 400px;

                .heading {
                    color: #FFF;
                    font-family: Inter;
                    font-size: 22.89px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin: 0;
                }

                .textArea {
                    display: flex;
                    height: 180px;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 12px;
                    align-self: stretch;

                    label {
                        color: var(--gray-white, #FFF);

                        /* tittle/16 M */
                        font-family: "DM Sans";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }

                    textarea {
                        display: flex;
                        padding: 12px 16px;
                        align-items: flex-start;
                        gap: 10px;
                        flex: 1 0 0;
                        align-self: stretch;
                        border-radius: 10px;
                        border: 1px solid #373737;
                        outline: none;
                        background: transparent;


                        color: #C4C4C4;
                        text-align: justify;

                        /* tittle/16 R */
                        font-family: "DM Sans";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }

                    .instruction {
                        align-self: stretch;
                        color: #494C4E;
                        text-align: right;

                        /* tittle/16 R */
                        font-family: "DM Sans";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                    .error {
                        font-family: Inter;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 16px;
                        text-align: left;
                        color: #EB5757;
                        padding: 0;
                        margin: 0;
                    }
                }

                .button {
                    display: flex;
                    // width: 506px;
                    height: 40px;
                    padding: 18px 30px;
                    // flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;

                    cursor: pointer;
                    color: var(--primary-white-1, #E0E4E7);

                    /* tittle/16 M */
                    font-family: "DM Sans";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;

                    border-radius: 8px;
                    border: 1px solid #212121;
                    background: #EE4950;
                }
            }
            .image{
                display: none;
            }
        }
    }
}
@media screen and (max-width: $breakpoint-mobile) {
    .container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 19.075px;
        flex: 1 0 0;
        align-self: stretch;
        box-sizing: border-box;

        .content {
            display: flex;
            justify-content: center;
            width: 100%;
            .form {
                display: flex;
                flex-direction: column;
                gap: 16px;
                width: 95%;

                .heading {
                    color: #FFF;
                    font-family: Inter;
                    font-size: 22.89px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin: 0;
                }

                .textArea {
                    display: flex;
                    height: 180px;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 12px;
                    align-self: stretch;

                    label {
                        color: var(--gray-white, #FFF);

                        /* tittle/16 M */
                        font-family: "DM Sans";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }

                    textarea {
                        display: flex;
                        padding: 12px 16px;
                        align-items: flex-start;
                        gap: 10px;
                        flex: 1 0 0;
                        align-self: stretch;
                        border-radius: 10px;
                        border: 1px solid #373737;
                        outline: none;
                        background: transparent;


                        color: #C4C4C4;
                        text-align: justify;

                        /* tittle/16 R */
                        font-family: "DM Sans";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }

                    .instruction {
                        align-self: stretch;
                        color: #494C4E;
                        text-align: right;

                        /* tittle/16 R */
                        font-family: "DM Sans";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                    .error {
                        font-family: Inter;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 16px;
                        text-align: left;
                        color: #EB5757;
                        padding: 0;
                        margin: 0;
                    }
                }

                .button {
                    display: flex;
                    // width: 506px;
                    height: 60px;
                    padding: 18px 30px;
                    // flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;

                    cursor: pointer;
                    color: var(--primary-white-1, #E0E4E7);

                    /* tittle/16 M */
                    font-family: "DM Sans";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;

                    border-radius: 8px;
                    border: 1px solid #212121;
                    background: #EE4950;
                }
            }
            .image{
                display: none;
            }
        }
    }
}
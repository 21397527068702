@import "../../variables.scss";

.dragDropArea {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 150px;
    width: 100%;
    border: 2px dashed $border-color;
    border-radius: 10px;
    background-color: #1e1e1e;
    color: $secondary-text-color;
    cursor: pointer;
    transition: border-color 0.3s ease;

    &.dragging {
        border-color: $primary-color;
    }

    .icon {
        font-size: 50px;
        margin-bottom: 10px;
    }

    .messageContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 3px;

        .message {
            font-size: 12px;
        }

        .subMessage {
            font-size: 10px;
            color: $primary-color;
            width: 80%;
            text-align: center;
        }
    }

    &:hover {
        border-color: $primary-color;
    }
}
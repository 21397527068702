@import "../../variables.scss";
@media screen and (min-width: $breakpoint-tablet) {
    .mobileSearchBtn{
        display: none;
    }
    .textField {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;

        .label {
            color: var(--gray-white, #FFF);

            /* tittle/16 M */
            font-family: "DM Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

        }

        .input {
            display: flex;

            align-items: center;
            gap: 10px;
            align-self: stretch;
            border-radius: 10px;
            border: 1px solid #373737;

            input {
                color: var(--primary-white-1, #E0E4E7);

                /* tittle/16 R */
                font-family: "DM Sans";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                background-color: transparent;
                border: none;
                width: 100%;
                height: 100%;
                padding: 15px 15px 15px 15px;

                box-sizing: border-box;
                border-radius: inherit;
                outline: none;

                &:-webkit-autofill {
                    -webkit-text-fill-color: var(--primary-white-1, #E0E4E7);
                    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
                }

                [type="password"] {
                    width: 400px;
                    padding-right: 0;
                }
            }

            .passwordField {
                color: var(--primary-white-1, #E0E4E7);

                /* tittle/16 R */
                font-family: "DM Sans";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                background-color: transparent;
                border: none;
                width: 100%;
                height: 100%;
                padding: 19px 21px 19px 20px;
                box-sizing: border-box;
                border-radius: inherit;
                outline: none;
            }

            img {
                width: 20px;
                height: 20px;
                object-fit: cover;
                margin-right: 10px;
                cursor: pointer;
            }
        }

        .error {
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            text-align: left;
            color: #EB5757;
            padding: 0;
            margin: 0;
        }
    }

    .selector {
        display: flex;
        padding: 6px 5px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        position: relative;
    
        .selected {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            
            .name {
                // width: 50px;
                min-width: 145px;
                
                color: #FFF;
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                /* 120% */
            }
        }

        .dropdown {
            position: absolute;
            top: 40px;
            left: -5px;
            display: none;
            flex-direction: column;
            // width: 180px;
            min-width: 180px;
            align-items: flex-start;
            border-radius: 0px 0px 8px 8px;
            border-right: 1px solid #252525;
            border-bottom: 1px solid #252525;
            border-left: 1px solid #252525;
            background: #1B1A1A;
            box-sizing: border-box;
            z-index: 10;
            /* Shadow/lg */
            box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);

            &:hover {
                display: flex;
            }

            div {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 12px;
                flex: 1 0 0;

                padding: 10px 14px;

                align-self: stretch;
                color: #FFF;
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;

                /* 150% */
                span {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                }

                &:hover {
                    background: #252525;
                }

                img:nth-child(2) {
                    display: none;
                }
            }

            .active {
                background: #EE4950;

                img:nth-child(2) {
                    display: block;
                }
            }
        }
    }

    .primaryButton {
        display: flex;
        width: 506px;
        height: 60px;
        padding: 18px 30px;
        // flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        cursor: pointer;
        color: var(--primary-white-1, #E0E4E7);

        /* tittle/16 M */
        font-family: "DM Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        border-radius: 8px;
        border: 1px solid #212121;
        background: #EE4950;
    }

    .secondaryButton {
        display: flex;
        width: 506px;
        height: 60px;
        padding: 18px 30px;
        // flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        cursor: pointer;
        color: var(--primary-white-1, #E0E4E7);

        /* tittle/16 M */
        font-family: "DM Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        border-radius: 8px;
        border: 1px solid #212121;
        background: #141414;
    }

    .searchBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        flex: 0 1 100%;

        padding: 8px 8px 8px 20px;

        align-self: stretch;
        gap: 10px;
        border-radius: 12px;
        border: 1px solid #404040;
        opacity: 0.9;
        background: #141414;
        box-sizing: border-box;

        .clearBtn {
            display: flex;
            padding: 12px 12px 12px 8px;
            align-items: center;
            gap: 8px;
            background: transparent;
            border: none;
            color: var(--Gray-6, #F2F2F2);
            text-align: center;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            cursor: pointer;
        }

        .textBox {
            flex: 1;
            height: 100%;
            color: #C4C4C4;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            background: transparent;
            border: none;
            outline: none;
        }
    }
}
@media screen and (min-width: $breakpoint-mobile) and (max-width: $breakpoint-tablet) {
    .mobileSearchBtn{
        display: none;
    }
    .textField {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;

        .label {
            color: var(--gray-white, #FFF);

            /* tittle/16 M */
            font-family: "DM Sans";
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

        }

        .input {
            display: flex;

            align-items: center;
            gap: 10px;
            align-self: stretch;
            border-radius: 10px;
            border: 1px solid #373737;

            input {
                color: var(--primary-white-1, #E0E4E7);

                /* tittle/16 R */
                font-family: "DM Sans";
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                background-color: transparent;
                border: none;
                width: 100%;
                height: 100%;
                padding: 19px 21px 19px 20px;
                box-sizing: border-box;
                border-radius: inherit;
                outline: none;

                &:-webkit-autofill {
                    -webkit-text-fill-color: var(--primary-white-1, #E0E4E7);
                    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
                }

                [type="password"] {
                    width: 400px;
                    padding-right: 0;
                }
            }

            .passwordField {
                color: var(--primary-white-1, #E0E4E7);

                /* tittle/16 R */
                font-family: "DM Sans";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                background-color: transparent;
                border: none;
                width: 100%;
                height: 100%;
                padding: 19px 21px 19px 20px;
                box-sizing: border-box;
                border-radius: inherit;
                outline: none;
            }

            img {
                width: 20px;
                height: 20px;
                object-fit: cover;
                margin-right: 10px;
                cursor: pointer;
            }
        }

        .error {
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            text-align: left;
            color: #EB5757;
            padding: 0;
            margin: 0;
        }
    }

    .selector {
        display: flex;
        padding: 6px 5px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        position: relative;
    
        .selected {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            
            .name {
                // width: 50px;
                min-width: 145px;
                
                color: #FFF;
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                /* 120% */
            }
        }

        .dropdown {
            position: absolute;
            top: 40px;
            left: -5px;
            display: none;
            flex-direction: column;
            // width: 180px;
            min-width: 180px;
            align-items: flex-start;
            border-radius: 0px 0px 8px 8px;
            border-right: 1px solid #252525;
            border-bottom: 1px solid #252525;
            border-left: 1px solid #252525;
            background: #1B1A1A;
            box-sizing: border-box;
            z-index: 10;
            /* Shadow/lg */
            box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);

            &:hover {
                display: flex;
            }

            div {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 12px;
                flex: 1 0 0;

                padding: 10px 14px;

                align-self: stretch;
                color: #FFF;
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;

                /* 150% */
                span {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                }

                &:hover {
                    background: #252525;
                }

                img:nth-child(2) {
                    display: none;
                }
            }

            .active {
                background: #EE4950;

                img:nth-child(2) {
                    display: block;
                }
            }
        }
    }

    .primaryButton {
        display: flex;
        width: 506px;
        height: 60px;
        padding: 15px 24px;
        // flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        cursor: pointer;
        color: var(--primary-white-1, #E0E4E7);

        /* tittle/16 M */
        font-family: "DM Sans";
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        border-radius: 8px;
        border: 1px solid #212121;
        background: #EE4950;
    }

    .secondaryButton {
        display: flex;
        width: 506px;
        height: 60px;
        padding: 15px 24px;
        // flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        cursor: pointer;
        color: var(--primary-white-1, #E0E4E7);

        /* tittle/16 M */
        font-family: "DM Sans";
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        border-radius: 8px;
        border: 1px solid #212121;
        background: #141414;
    }

    .searchBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        flex: 0 1 100%;

        padding: 8px 8px 8px 20px;

        align-self: stretch;
        gap: 10px;
        border-radius: 12px;
        border: 1px solid #404040;
        opacity: 0.9;
        background: #141414;
        box-sizing: border-box;

        .clearBtn {
            display: flex;
            padding: 12px 12px 12px 8px;
            align-items: center;
            gap: 8px;
            background: transparent;
            border: none;
            color: var(--Gray-6, #F2F2F2);
            text-align: center;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            cursor: pointer;
        }

        .textBox {
            flex: 1;
            height: 100%;
            color: #C4C4C4;
            font-family: Inter;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            background: transparent;
            border: none;
            outline: none;
        }
    }
}
@media screen and (max-width: $breakpoint-mobile) {
    .searchBtn{
        display: none;
    }
    .textField {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;
        width: 100%;
        .label {
            color: var(--gray-white, #FFF);

            /* tittle/16 M */
            font-family: "DM Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

        }

        .input {
            display: flex;

            align-items: center;
            gap: 10px;
            align-self: stretch;
            border-radius: 10px;
            border: 1px solid #373737;

            input {
                color: var(--primary-white-1, #E0E4E7);

                /* tittle/16 R */
                font-family: "DM Sans";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                background-color: transparent;
                border: none;
                width: 100%;
                height: 100%;
                padding: 19px 21px 19px 20px;
                box-sizing: border-box;
                border-radius: inherit;
                outline: none;

                &:-webkit-autofill {
                    -webkit-text-fill-color: var(--primary-white-1, #E0E4E7);
                    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
                }

                [type="password"] {
                    width: 400px;
                    max-width: 100%;
                    padding-right: 0;
                }
            }

            .passwordField {
                color: var(--primary-white-1, #E0E4E7);

                /* tittle/16 R */
                font-family: "DM Sans";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                background-color: transparent;
                border: none;
                width: 100%;
                height: 100%;
                padding: 19px 21px 19px 20px;
                box-sizing: border-box;
                border-radius: inherit;
                outline: none;
            }

            img {
                width: 20px;
                height: 20px;
                object-fit: cover;
                margin-right: 10px;
                cursor: pointer;
            }
        }

        .error {
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            text-align: left;
            color: #EB5757;
            padding: 0;
            margin: 0;
        }
    }

    .selector {
        display: flex;
        padding: 6px 5px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        position: relative;
    
        .selected {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            
            .name {
                // width: 50px;
                min-width: 145px;
                
                color: #FFF;
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                /* 120% */
            }
        }

        .dropdown {
            position: absolute;
            top: 40px;
            left: -5px;
            display: none;
            flex-direction: column;
            // width: 180px;
            min-width: 180px;
            align-items: flex-start;
            border-radius: 0px 0px 8px 8px;
            border-right: 1px solid #252525;
            border-bottom: 1px solid #252525;
            border-left: 1px solid #252525;
            background: #1B1A1A;
            box-sizing: border-box;
            z-index: 10;
            /* Shadow/lg */
            box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);

            &:hover {
                display: flex;
            }

            div {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 12px;
                flex: 1 0 0;

                padding: 10px 14px;

                align-self: stretch;
                color: #FFF;
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;

                /* 150% */
                span {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                }

                &:hover {
                    background: #252525;
                }

                img:nth-child(2) {
                    display: none;
                }
            }

            .active {
                background: #EE4950;

                img:nth-child(2) {
                    display: block;
                }
            }
        }
    }

    .primaryButton {
        display: flex;
        width: 100%;
        height: 60px;
        padding: 9px 15px;
        // flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        cursor: pointer;
        color: var(--primary-white-1, #E0E4E7);

        /* tittle/16 M */
        font-family: "DM Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        border-radius: 8px;
        border: 1px solid #212121;
        background: #EE4950;
        box-sizing: border-box;
    }

    .secondaryButton {
        display: flex;
        width: 506px;
        height: 60px;
        padding: 18px 30px;
        // flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        cursor: pointer;
        color: var(--primary-white-1, #E0E4E7);

        /* tittle/16 M */
        font-family: "DM Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        border-radius: 8px;
        border: 1px solid #212121;
        background: #141414;
    }

    .searchBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        
        flex: 0 1 100%;

        padding: 8px 8px 8px 20px;

        align-self: stretch;
        gap: 10px;
        border-radius: 12px;
        border: 1px solid #404040;
        opacity: 0.9;
        background: #141414;
        box-sizing: border-box;
        .icon{
            width: 20px;
        }
        .clearBtn {
            display: flex;
            padding: 12px 12px 12px 8px;
            align-items: center;
            // gap: 8px;
            background: transparent;
            border: none;
            color: var(--Gray-6, #F2F2F2);
            text-align: center;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            cursor: pointer;
        }

        .textBox {
            flex: 1;
            height: 100%;
            color: #C4C4C4;
            font-family: Inter;
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            // line-height: normal;
            background: transparent;
            border: none;
            outline: none;
        }
    }
}
@import "../../variables.scss";
@import '../../common';

$primary-font: Inter;


.ck_editor {
    position: relative;
    @include input-text-style;
    color: white !important;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid $border-color;

    // height: 100%;
    // max-height: 45vh;
    height: inherit;
    // height: 270px;

    &::placeholder {
        color: var(--colors-style-neutrals-n-60, #b3b9c4);
    }

    >div:first-child {
        background-color: #1B1A1A !important;
        border-color: transparent;
        position: sticky;
        top: 0;

        // margin-top: 20px;

        ul[aria-label="Font Size"] {
            overflow-y: auto;
            height: 250px;
            @include scrollbar-style;

            span {
                font-size: 14px !important;
            }
        }
    }


    >div:nth-child(2) {
        box-shadow: none;
        padding-top: 16px;
        padding-inline: 16px;
        box-sizing: border-box;
        height: calc(100% - 39.5px);
        min-height: 270px;

        background-color: #1B1A1A !important;

        color: white;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: lighter;
        line-height: 140%;


        @include scrollbar-style;

        &:focus {
            border: 1px solid $primary-color !important;
        }

        &:active {
            border: 1px solid $primary-color !important;
        }

        
        @media (min-width: 1100px) {
            height: calc(100% - 40px);
        }
        @media  (min-width: 2100px) {
            height: calc(100% - 42px);
        }
        @media  (min-width: 3000px) {
            height: calc(100% - 44px);
        }
        @media  (min-width: 4270px) {
            height: calc(100% - 47px);
        }
    }
}
@import "../../variables.scss";
@media screen and (min-width: $breakpoint-mobile) {
    .users {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 32px;
        // background: red;
        box-sizing: border-box;
        align-self: stretch;
        height: fit-content;

        .searchContainer {}

        .container {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;

            .btnContainer {
                width: 35%;
                height: 40px;
            }
        }
    }


}

@media screen and (max-width: $breakpoint-mobile) {
    .users {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 32px;
        // background: red;
        box-sizing: border-box;
        align-self: stretch;
        height: fit-content;

        .container {
            width: 100%;
            display: flex;
            flex-wrap: wrap-reverse;
            justify-content: flex-end;
            align-items: center;
            gap: 20px;
        }

        .table {
            width: 100%;
            overflow: scroll;

            * {
                text-wrap: nowrap;
            }
        }


    }

}
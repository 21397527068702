$breakpoint-sm-phone: 320px;
$breakpoint-landscap-mobile: 480px;
$breakpoint-mobile: 768px;
$breakpoint-tablet: 1023px;
$breakpoint-laptop: 1180px;

// Color Palette
$primary-color: #EE4950;
$secondary-color: #ee565d;
$primary-text-color: #fafafa;
$secondary-text-color: #AFB6B2;
$border-color: #373737;
$modal-background-color: #1B1A1A;

// Typography
$font-family-base: Inter;
$font-size-base: 16px;
$font-size-sm: 14px;
$font-size-lg: 18px;
$font-weight-normal: 400;
$font-weight-bold: 700;

// Spacing (Padding/Margin)
$spacing-xs: 4px;
$spacing-sm: 8px;
$spacing-md: 16px;
$spacing-lg: 24px;
$spacing-xl: 32px;

// Border Radius
$border-radius-sm: 4px;
$border-radius-md: 8px;
$border-radius-lg: 12px;

// Shadows
$box-shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.12);
$box-shadow-md: 0 3px 6px rgba(0, 0, 0, 0.16);
$box-shadow-lg: 0 5px 10px rgba(0, 0, 0, 0.2);

// Transition
$transition-fast: 0.2s ease-in-out;
$transition-normal: 0.3s ease-in-out;
$transition-slow: 0.5s ease-in-out;

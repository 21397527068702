.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}
.content{
    display: flex;
    width: 250px;
    padding: 64px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    border-radius: 10px;
    background: #1B1A1A;

    /* Shadow/xl */
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    img{
        width: 100px;
        height: 100px;
    }
}
.text{
    font-size: larger;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
    font-family: Inter;
}

.progress_bar_overlay{
    width: 100%;
    height: 20px;
    background-color: blue;
    border-radius: "5px";
    margin-top: "10px";
    display: flex;
    gap: 10px;
}

    .inner_bar{
      height: 100%;
      background-color: white;
      border-radius: 5px;
      transition: width 0.3s ease,

    }
.progress_text{
    font-size: 20px;
        color: white;
    }
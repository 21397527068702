@import "../../variables.scss";
@import "../../common";

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.viewEmailModalContainer {
    position: relative;
    width: 830px;
    display: flex;
    // height: 700px;
    height: calc(100vh - 100px);
    justify-content: start;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    background-color: #1B1A1A;
    color: $primary-text-color;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19);
    font-family: $font-family-base;

    .modalHeader {
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        padding-inline: 20px;
        box-sizing: border-box;
        border-bottom: 1px solid #373737;

        .title {
            font-family: $font-family-base;
            font-size: 1.5rem;

        }

        @media screen and (max-width: 768px) {
            .title {
                font-size: 1.5rem;
            }
        }

        .close {
            cursor: pointer;

            img {
                width: 20px;
                height: 20px;
            }

            padding: 4px;
        }
    }

    .modalBody {
        width: 100%;
        // height: 70vh;
        // height: calc(100vh - 231px);
        display: flex;
        flex-direction: column;
        gap: 14px;
        position: relative;
        overflow-y: auto;
        @include scrollbar-style;
        padding: 20px;
        box-sizing: border-box;

        .label{
            font-size: 14px;
            font-weight: bold;
            padding-bottom: 6px;
            text-transform: uppercase;
            color: $primary-color;
        }
        .box {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 4px;
            
            border-radius: 10px;
            border: 1px solid #373737;

            color: var(--primary-white-1, #E0E4E7);

            /* tittle/16 R */
            font-family: "DM Sans";
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            background-color: transparent;
            letter-spacing: 0.03cm;
            width: 100%;
            height: fit-content;
            padding: 13px;
            box-sizing: border-box;
            text-transform: capitalize;
        }

        .tags {
            background-color: silver;
            color: #1B1A1A;
            border-radius: 12px;
            padding: 4px;
            padding-inline: 12px;
            position: relative;

            font-size: small;
            font-weight: 500;
            .tagClose {
                width: 14px;
                height: auto;
                padding: 4px;
                position: absolute;
                top: -8px;
                background-color: $primary-color;
                border-radius: 100%;
                right: -20px;
            }
        }

        .viewAllLess {
            cursor: pointer;
            color: $primary-color;
            font-size: 14px;
            font-weight: 500;
            text-decoration: underline;
        }

        .emailBody{
            width: fit-content;
            img{
                width: 100%;
            }
        }
    }

    .modalFooter {
        width: 100%;
        display: flex;
        justify-content: end;
        padding: 20px;
        box-sizing: border-box;

        box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
        border-top: 1px solid #373737;
    }
}
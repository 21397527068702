@import "../../variables.scss";
// @import url('https://fonts.cdnfonts.com/css/bauhaus-93');
@media screen and (min-width: $breakpoint-mobile) {
    .footer {
        padding-bottom: 48px;

        hr {
            height: 1px;
            align-self: stretch;
            margin: 0;
            border: none;
            background: #252525;
        }

        .footerContent {
            padding: 32px 120px 0 120px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .poweredBy {
                color: #EE4950;
                text-align: center;
                font-family: "Bauhaus 93";
                font-size: 32px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin: 0;
            }

            .copyright {
                color: #C4C4C4;

                /* Text md/Regular */
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                /* 150% */
            }
        }
    }
}

@media screen and (max-width: $breakpoint-mobile) {
    .footer {
        padding-bottom: 40px;

        hr {
            height: 1px;
            align-self: stretch;
            margin: 0;
            border: none;
            background: #252525;
        }

        .footerContent {
            padding: 32px 20px 0 20px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;

            .poweredBy {
                color: #EE4950;
                text-align: center;
                font-family: "Bauhaus 93";
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin: 0;
            }

            .copyright {
                color: #C4C4C4;

                /* Text md/Regular */
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                /* 150% */
            }
        }
    }
}
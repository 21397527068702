@import "../../variables.scss";
@media screen and (min-width: $breakpoint-mobile) {
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1000;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .createModal {
        display: flex;
        width: 830px;
        padding: 64px;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        border-radius: 10px;
        background: #1B1A1A;
    
        /* Shadow/xl */
        box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    
        .top {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            align-self: stretch;
    
            .header {
                display: flex;
                align-items: center;
                gap: 10px;
                align-self: stretch;
                position: relative;
    
                h1 {
                    color: #EE4950;
                    text-align: center;
                    font-family: Inter;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 28px;
                    /* 87.5% */
                    margin: 0;
                    flex: 1;
                }
    
                .close {
                    position: absolute;
                    background: transparent;
                    border: none;
                    cursor: pointer;
                    right: 0;
                }
            }
    
            .subtitle {
                color: #C4C4C4;
                text-align: center;
                font-family: Inter;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                width: 100%;
                margin: 0;
                /* 100% */
            }
        }
    
        .bottom {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
            align-self: stretch;
    
            .upload {
                display: flex;
                padding: 16px 24px;
                flex-direction: column;
                align-items: center;
                gap: 12px;
                align-self: stretch;
                border-radius: 8px;
                border: 1px dashed #454647;
                cursor: pointer;
    
                .uploadText {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 4px;
                    align-self: stretch;
                    cursor: pointer;
    
                    .line1 {
                        color: #FFF;
    
                        /* Text sm/Semibold */
                        font-family: Inter;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px;
                        margin: 0;
    
                        /* 142.857% */
                        span {
                            color: #707276;
    
                            /* Text sm/Regular */
                            font-family: Inter;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 20px;
                            /* 142.857% */
                        }
                    }
    
                    .line2 {
                        color: #707276;
                        text-align: center;
    
                        /* Text xs/Regular */
                        font-family: Inter;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px;
                        margin: 0;
                        /* 150% */
                    }
                }
            }
    
            .form {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                gap: 16px;
                align-self: stretch;
    
                .row, .mobileRow {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    gap: 16px;
                    align-self: stretch;
                }
                .mobileRow{
                    display: none;
                }
                
    
                .textArea {
                    display: flex;
                    height: 180px;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 12px;
                    align-self: stretch;
    
                    label {
                        color: var(--gray-white, #FFF);
    
                        /* tittle/16 M */
                        font-family: "DM Sans";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
    
                    textarea {
                        display: flex;
                        padding: 12px 16px;
                        align-items: flex-start;
                        gap: 10px;
                        flex: 1 0 0;
                        align-self: stretch;
                        border-radius: 10px;
                        border: 1px solid #373737;
                        outline: none;
                        background: transparent;
    
    
                        color: #C4C4C4;
                        text-align: justify;
    
                        /* tittle/16 R */
                        font-family: "DM Sans";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
    
                    .instruction {
                        align-self: stretch;
                        color: #494C4E;
                        text-align: right;
    
                        /* tittle/16 R */
                        font-family: "DM Sans";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                    .error {
                        font-family: Inter;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 16px;
                        text-align: left;
                        color: #EB5757;
                        padding: 0;
                        margin: 0;
                    }
                }
            }
    
            .buttons {
                display: flex;
                align-items: flex-start;
                gap: 12px;
                align-self: stretch;
    
                .cancel {
                    display: flex;
                    padding: 10px 18px;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    flex: 1 0 0;
                    border-radius: 8px;
    
                    /* Shadow/xs */
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                    border: none;
                    background: transparent;
                    color: #929292;
    
                    /* Text md/Semibold */
                    font-family: Inter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    /* 150% */
                    cursor: pointer;
                }
    
                .publish {
                    display: flex;
                    padding: 10px 18px;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    flex: 1 0 0;
                    border-radius: 8px;
                    border: 1px solid #232323;
                    background: #232323;
    
                    /* Shadow/xs */
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                    color: #535151;
    
                    /* Text md/Semibold */
                    font-family: Inter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    /* 150% */
                    cursor: pointer;
                }
    
                .red {
                    border-radius: 8px;
                    border: 1px solid #EE4950;
                    background: #EE4950;
    
                    /* Shadow/xs */
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                    color: var(--White, #FFF);
    
                    /* Text md/Semibold */
                    font-family: Inter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    /* 150% */
                }
            }
    
            .selectedImg {
                display: flex;
                align-items: flex-start;
                gap: 16px;
                width: 100%;
                align-items: center;
                position: relative;
                padding-right: 20px;
    
                .delete {
                    position: absolute;
                    right: 0;
                    top: 0;
                }
    
                .img {
                    width: 64px;
                    height: 64px;
                    border-radius: 2px;
                }
    
                .titleAndLoader {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 4px;
                    flex: 1;
    
    
                    div {
                        display: flex;
                        flex-direction: column;
    
                        .title {
                            color: #C4C4C4;
    
                            /* Text sm/Medium */
                            font-family: Inter;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 20px;
                            /* 142.857% */
                        }
    
                        .size {
                            color: #7A8580;
    
                            /* Text sm/Regular */
                            font-family: Inter;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 20px;
                            /* 142.857% */
                        }
    
    
                    }
    
                    .loader {
    
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        gap: 12px;
                        align-self: stretch;
                        justify-content: space-between;
    
                        .bar {
                            height: 8px;
                            border-radius: 4px;
                            background: transparent;
                            border: 0.5px solid #823134;
                            flex: 1;
    
                            .fill {
                                height: 100%;
                                background: #823134;
                                border-radius: 4px;
                            }
                        }
    
    
                        span {
                            color: #C4C4C4;
    
                            /* Text sm/Medium */
                            font-family: Inter;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 20px;
                            /* 142.857% */
                        }
                    }
    
    
                }
            }
        }
    }
    
    .viewModal {
        .mobileBtn {
            display: none;
        }
        display: inline-flex;
        padding: 32px;
        flex-direction: column;
        align-items: flex-end;
        gap: 23px;
        border-radius: 10px;
        width: 830px;
        background: #1B1A1A;
        .close{
            cursor: pointer;
        }
        .content {
            display: flex;
            padding: 0px 32px;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 32px;
            width: 100%;
            // background: red;
            box-sizing: border-box;
            .top {
                display: flex;
                width: 100%;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 16px;
                // background: rebeccapurple;
                h1 {
                    margin: 0;
                    color: #EE4950;
                    font-family: Inter;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 28px;
                    /* 87.5% */
                }
    
                p {
                    color: #C4C4C4;
                    text-align: justify;
                    font-family: Inter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    /* 125% */
                    margin: 0;
                }
            }
    
            .bottom {
                display: flex;
                width: 100%;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                // background: saddlebrown;
                gap: 24px;
                img{
                    width:100%;
                    max-height: 400px;
                }
            }
        }
    }
    .watchVideo {
        display: flex;
        width: 830px;
        padding: 24px;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        border-radius: 10px;
        background: #1B1A1A;
        .close{
            display: flex;
            justify-content: flex-end;
            // background-color: rebeccapurple;
            width: 100%;
            img{
                cursor: pointer;
            }
        }
        .content {
            display: flex;
            padding: 0px 32px;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            // gap: 10px;
            width: 100%;
            .paymentNotification{
                display: flex;
                width: 100%;
                flex-direction: column;
                align-items: center;
                p{
                    font-size: 20px;
                color: white;
                font-family: Inter;
                }
            }
            .video{
                width: 100%;
                max-height: 300px;
            }
            .title{
                color: white;
                font-family: Inter;
                font-size: 20px;
                padding: 0;
                margin: 0;
            }
            .info {
                display: flex;
                width: 100%;
                gap: 30px;
                color: #707276;
                font-family: Inter;
                font-size: 12px;
                font-style: normal;
            }
            .description{
                color: white;
                font-family: Inter;
                font-size: 16px;
            }
        }

    }
    .noData {
        display: flex;
        justify-content: center;
        align-items: center;
    
        flex-direction: column;
        height: 100%;
        width: 100%;
        color: var(--neutral-400-night, #626A66);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        cursor: default;
    
        img {
            width: 100px;
            height: 100px;
        }
    
    }
    .downloadDetailModal{
        display: flex;
        width: 830px;
        padding: 24px;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        border-radius: 10px;
        background: #1B1A1A;
        .close{
            display: flex;
            justify-content: flex-end;
            // background-color: rebeccapurple;
            width: 100%;
            img{
                cursor: pointer;
            }
        }
        .content {
            display: flex;
            padding: 32px 32px;
            flex-direction: column;
            align-items: flex-start;
            max-height: 400px;
            overflow-y: auto;
            box-sizing: border-box;
            // scrollbar-width: thin;
            // scrollbar-color: var(--neutral-400-night, #626A66) transparent;
            width: 100%;
        }
        
        /* Webkit browsers (Chrome, Safari, Edge) */
        .content::-webkit-scrollbar {
            width: 12px;
        }
        
        .content::-webkit-scrollbar-track {
            background: transparent; /* You can change this to any color */
        }
        
        .content::-webkit-scrollbar-thumb {
            background-color: var(--neutral-400-night, #626A66); 
            border-radius: 10px; /* Makes the thumb round */
            border: 3px solid transparent; /* Add border for some padding around thumb */
            background-clip: content-box; /* Ensures the thumb is rounded */
        }
        
        .content::-webkit-scrollbar-thumb:hover {
            background-color: #555; /* Changes color on hover */
        }
        
        .content .exportBtn {
            width: fit-content;
            border: none;
            border-radius: 5px;
            background-color: #EE4950;
            color: white;
            font-size: 16px;
            padding: 10px;
            cursor: pointer;
            align-self: flex-end;
        }
        
    }
    
}
@media screen and (max-width: $breakpoint-mobile) {
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1000;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .createModal {
        display: flex;
        width: 90%;
        padding: 20px;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        border-radius: 10px;
        background: #1B1A1A;
        box-sizing: border-box;
        /* Shadow/xl */
        box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    
        .top {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            align-self: stretch;
    
            .header {
                display: flex;
                align-items: center;
                gap: 7px;
                align-self: stretch;
                position: relative;
    
                h1 {
                    color: #EE4950;
                    text-align: center;
                    font-family: Inter;
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 28px;
                    /* 87.5% */
                    margin: 0;
                    flex: 1;
                }
    
                .close {
                    position: absolute;
                    background: transparent;
                    border: none;
                    cursor: pointer;
                    right: 0;
                }
            }
    
            .subtitle {
                color: #C4C4C4;
                text-align: center;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                width: 100%;
                margin: 0;
                /* 100% */
            }
        }
    
        .bottom {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 15px;
            align-self: stretch;
    
            .upload {
                display: flex;
                padding: 16px 24px;
                flex-direction: column;
                align-items: center;
                gap: 12px;
                align-self: stretch;
                border-radius: 8px;
                border: 1px dashed #454647;
                cursor: pointer;
    
                .uploadText {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 4px;
                    align-self: stretch;
                    cursor: pointer;
    
                    .line1 {
                        color: #FFF;
    
                        /* Text sm/Semibold */
                        font-family: Inter;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px;
                        margin: 0;
    
                        /* 142.857% */
                        span {
                            color: #707276;
    
                            /* Text sm/Regular */
                            font-family: Inter;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 20px;
                            /* 142.857% */
                        }
                    }
    
                    .line2 {
                        color: #707276;
                        text-align: center;
    
                        /* Text xs/Regular */
                        font-family: Inter;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px;
                        margin: 0;
                        /* 150% */
                    }
                }
            }
    
            .form {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                gap: 12px;
                align-self: stretch;
    
                .row, .mobileRow {
                    display: none;
                    justify-content: space-between;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 12px;
                    align-self: stretch;
                }
                .mobileRow{
                    display: flex;
                }
    
                .textArea {
                    display: flex;
                    height: 140px;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 12px;
                    align-self: stretch;
    
                    label {
                        color: var(--gray-white, #FFF);
    
                        /* tittle/16 M */
                        font-family: "DM Sans";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
    
                    textarea {
                        display: flex;
                        padding: 12px 16px;
                        align-items: flex-start;
                        gap: 10px;
                        flex: 1 0 0;
                        align-self: stretch;
                        border-radius: 10px;
                        border: 1px solid #373737;
                        outline: none;
                        background: transparent;
    
    
                        color: #C4C4C4;
                        text-align: justify;
    
                        /* tittle/16 R */
                        font-family: "DM Sans";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
    
                    .instruction {
                        align-self: stretch;
                        color: #494C4E;
                        text-align: right;
    
                        /* tittle/16 R */
                        font-family: "DM Sans";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                    .error {
                        font-family: Inter;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 16px;
                        text-align: left;
                        color: #EB5757;
                        padding: 0;
                        margin: 0;
                    }
                }
            }
    
            .buttons {
                display: flex;
                align-items: flex-start;
                gap: 12px;
                align-self: stretch;
    
                .cancel {
                    display: flex;
                    padding: 10px 18px;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    flex: 1 0 0;
                    border-radius: 8px;
    
                    /* Shadow/xs */
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                    border: none;
                    background: transparent;
                    color: #929292;
    
                    /* Text md/Semibold */
                    font-family: Inter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    /* 150% */
                    cursor: pointer;
                }
    
                .publish {
                    display: flex;
                    padding: 10px 18px;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    flex: 1 0 0;
                    border-radius: 8px;
                    border: 1px solid #232323;
                    background: #232323;
    
                    /* Shadow/xs */
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                    color: #535151;
    
                    /* Text md/Semibold */
                    font-family: Inter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    /* 150% */
                    cursor: pointer;
                }
    
                .red {
                    border-radius: 8px;
                    border: 1px solid #EE4950;
                    background: #EE4950;
    
                    /* Shadow/xs */
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                    color: var(--White, #FFF);
    
                    /* Text md/Semibold */
                    font-family: Inter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    /* 150% */
                }
            }
    
            .selectedImg {
                display: flex;
                align-items: flex-start;
                gap: 16px;
                width: 100%;
                align-items: center;
                position: relative;
                padding-right: 20px;
    
                .delete {
                    position: absolute;
                    right: 0;
                    top: 0;
                }
    
                .img {
                    width: 64px;
                    height: 64px;
                    border-radius: 2px;
                }
    
                .titleAndLoader {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 4px;
                    flex: 1;
    
    
                    div {
                        display: flex;
                        flex-direction: column;
    
                        .title {
                            color: #C4C4C4;
    
                            /* Text sm/Medium */
                            font-family: Inter;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 20px;
                            /* 142.857% */
                        }
    
                        .size {
                            color: #7A8580;
    
                            /* Text sm/Regular */
                            font-family: Inter;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 20px;
                            /* 142.857% */
                        }
    
    
                    }
    
                    .loader {
    
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        gap: 12px;
                        align-self: stretch;
                        justify-content: space-between;
    
                        .bar {
                            height: 8px;
                            border-radius: 4px;
                            background: transparent;
                            border: 0.5px solid #823134;
                            flex: 1;
    
                            .fill {
                                height: 100%;
                                background: #823134;
                                border-radius: 4px;
                            }
                        }
    
    
                        span {
                            color: #C4C4C4;
    
                            /* Text sm/Medium */
                            font-family: Inter;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 20px;
                            /* 142.857% */
                        }
                    }
    
    
                }
            }
        }
    }
    
    .viewModal {
        .btn{
            display: none !important;
        }
        display: inline-flex;
        padding: 16px;
        flex-direction: column;
        align-items: flex-end;
        gap: 23px;
        border-radius: 10px;
        width: 90%;
        background: #1B1A1A;
        box-sizing: border-box;
        .close{
            cursor: pointer;
        }
        .content {
            display: flex;
            padding: 0px 32px;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 32px;
            width: 100%;
            // background: red;
            box-sizing: border-box;
            .top {
                display: flex;
                width: 100%;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 16px;
                // background: rebeccapurple;
                h1 {
                    margin: 0;
                    color: #EE4950;
                    font-family: Inter;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 28px;
                    /* 87.5% */
                }
    
                p {
                    color: #C4C4C4;
                    text-align: justify;
                    font-family: Inter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    /* 125% */
                    margin: 0;
                }
            }
    
            .bottom {
                display: flex;
                width: 100%;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                // background: saddlebrown;
                gap: 24px;
                img{
                    width:100%;
                    max-height: 400px;
                }
            }
        }
    }
    .noData {
        display: flex;
        justify-content: center;
        align-items: center;
    
        flex-direction: column;
        height: 100%;
        width: 100%;
        color: var(--neutral-400-night, #626A66);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        cursor: default;
    
        img {
            width: 100px;
            height: 100px;
        }
    
    }
    
}
.titleAndBtn{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-top: 8px;
    .downloadBtn{
        cursor: pointer;
        border: none;
        background-color: #EE4950;
        color: white;
        font-size: 14px;
        font-weight: 500;
        padding: 8px 16px;
        border-radius: 10px;
    }
}
@import "../../variables.scss";
@media screen and (min-width: $breakpoint-mobile) {
    .list {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        flex: 1 0 0;
        align-self: stretch;

        .item {
            position: relative;
            display: flex;
            padding: 24px;
            align-items: center;
            gap: 24px;
            align-self: stretch;
            border-radius: 8px;
            background: #252525;

            img {
                cursor: pointer;
            }

            .dropdown {
                position: absolute;
                // display: flex;
                right: 50px;
                top: 10px;
                width: 190px;
                padding: 10px 14px;
                align-items: center;
                gap: 8px;
                border-radius: 4px;
                background: #373737;
                box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.08);
                color: #FFF !important;
                font-family: Inter;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                /* 160% */
                display: none;
            }

            .name {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 16px;
                flex: 1 0 0;
                align-self: stretch;

                .title {
                    color: var(--neutral-800-night, #AFB6B2);
                    font-family: Inter;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin: 0;
                }

                .artist {
                    color: var(--neutral-600-night, #7A8580);
                    font-family: Inter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin: 0;
                }
            }
        }
    }

    .noData {
        display: flex;
        justify-content: center;
        align-items: center;

        flex-direction: column;
        height: 100%;
        width: 100%;
        color: var(--neutral-400-night, #626A66);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        cursor: default;

        img {
            width: 100px;
            height: 100px;
        }

    }
}
@media screen and (max-width: $breakpoint-mobile) {
    .list {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        flex: 1 0 0;
        align-self: stretch;

        .item {
            position: relative;
            display: flex;
            padding: 16px;
            align-items: center;
            gap: 16px;
            align-self: stretch;
            border-radius: 8px;
            background: #252525;

            img {
                cursor: pointer;
                width: 24px;
            }

            .dropdown {
                position: absolute;
                // display: flex;
                right: 50px;
                top: 10px;
                width: 190px;
                padding: 10px 14px;
                align-items: center;
                gap: 8px;
                border-radius: 4px;
                background: #373737;
                box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.08);
                color: #FFF !important;
                font-family: Inter;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                /* 160% */
                display: none;
            }

            .name {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 12px;
                flex: 1 0 0;
                align-self: stretch;

                .title {
                    color: var(--neutral-800-night, #AFB6B2);
                    font-family: Inter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin: 0;
                }

                .artist {
                    color: var(--neutral-600-night, #7A8580);
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin: 0;
                }
            }
        }
    }

    .noData {
        display: flex;
        justify-content: center;
        align-items: center;

        flex-direction: column;
        height: 100%;
        width: 100%;
        color: var(--neutral-400-night, #626A66);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        cursor: default;

        img {
            width: 100px;
            height: 100px;
        }

    }
}
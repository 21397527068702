.divider {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    hr {
        width: 230px;
        height: 1px;
        background: #252525;
        border: none;
    }

    span {
        color: var(--primary-white-1, #E0E4E7);

        /* bodi 18 (medium) */
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 27px;
        /* 150% */
    }
}
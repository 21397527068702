@import "../../variables.scss";
@media screen and (min-width: $breakpoint-tablet) {
    .container {
        width: 100%;
        align-self: stretch;
        .mobileButton {
            display: none;
        }
        .paytip {
            display: flex;
            padding: 64px 32px;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            align-self: stretch;
            border-radius: 10px;
            background: #1B1A1A;
            width: 100%;
            box-sizing: border-box;

            .top {}

            .bottom {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 24px;
                align-self: stretch;
                // background-color:wheat;
                .text {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 16px;
                    align-self: stretch;

                    h1 {
                        color: #EE4950;
                        text-align: center;
                        font-family: Inter;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        margin: 0;
                    }

                    p {
                        color: #E7E7E7;
                        text-align: center;
                        font-family: Inter;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                        margin: 0;
                    }
                }
            }
        }
    }
}
@media screen and (min-width: $breakpoint-mobile) and (max-width: $breakpoint-tablet) {
    .container {
        width: 100%;
        align-self: stretch;
        .mobileButton {
            display: none;
        }
        .paytip {
            display: flex;
            padding: 28px 12px;
            flex-direction: column;
            align-items: center;
            gap: 14px;
            align-self: stretch;
            border-radius: 10px;
            background: #1B1A1A;
            width: 100%;
            box-sizing: border-box;

            .top {
                display: flex;
                justify-content: center;
                img{
                    width: 50%;
                    height: 50%;
                }
            }

            .bottom {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 16px;
                align-self: stretch;

                .text {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 12px;
                    align-self: stretch;

                    h1 {
                        color: #EE4950;
                        text-align: center;
                        font-family: Inter;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        margin: 0;
                    }

                    p {
                        color: #E7E7E7;
                        text-align: center;
                        font-family: Inter;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                        margin: 0;
                    }
                }

            }
        }
    }
}
@media screen and (max-width: $breakpoint-mobile) {
    .container {
        width: 100%;
        align-self: stretch;
        .mobileButton {
            display: flex;
        }
        .button{
            display: none;
        }
        .paytip {
            display: flex;
            padding: 64px 32px;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            align-self: stretch;
            border-radius: 10px;
            background: #1B1A1A;
            width: 100%;
            box-sizing: border-box;

            .top {
                display: flex;
                justify-content: center;
                img{
                    width: 70%;
                }
            }

            .bottom {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 24px;
                align-self: stretch;

                .text {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 16px;
                    align-self: stretch;

                    h1 {
                        color: #EE4950;
                        text-align: center;
                        font-family: Inter;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        margin: 0;
                    }

                    p {
                        color: #E7E7E7;
                        text-align: center;
                        font-family: Inter;
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                        margin: 0;
                    }
                }

            }
        }
    }
}
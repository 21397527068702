@import "../../variables.scss";

@media screen and (min-width: $breakpoint-tablet) {
    .login {
        .container {
            display: flex;
            justify-content: space-between;
            overflow: hidden;
            height: 990px;
            margin-bottom: 0;
            padding-bottom: 0;
    
            .left {
                display: flex;
                align-items: center;
                padding: 129px auto auto 120px;
    
                .form {
    
                    padding: 20px 100px;
                    box-sizing: border-box;
    
                    .top {
                        display: inline-flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 16px;
                        margin-bottom: 76px;
    
                        .title {
                            color: var(--gray-white, #FFF);
    
                            /* Heading/H1 */
                            font-family: "DM Sans";
                            font-size: 30px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            margin: 0;
                        }
    
                        .subtitle {
                            color: var(--gray-dark-4, #7C7C8D);
    
                            /* tittle/18R */
                            font-family: "DM Sans";
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin: 0;
    
                            a {
                                text-decoration: none;
                                color: #EE4950;
    
                                /* tittle/18 M */
                                font-family: "DM Sans";
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                            }
                        }
                    }
    
                    .googleSignIn {
                        margin-bottom: 24px;
                    }
    
                    .formFields {
                        display: flex;
                        width: 506px;
                        padding: 20px 0px;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 14px;
                        box-sizing: border-box;
                        margin: 24px auto;
                    }
    
                    .terms {
                        display: flex;
                        justify-content: space-between;
    
                        .rememberMe {
                            text-wrap: nowrap;
                            margin-bottom: 30px;
                            display: flex;
                            gap: 13px;
                            align-items: center;
    
                            input[type="checkbox"] {
                                width: 20px;
                                height: 20px;
                                border-radius: 4px;
                                border: 1.5px solid var(--gray-dark-4, #7C7C8D);
                                // background: #a02a2a;
                                accent-color: transparent;
                                appearance: none;
                                
                            }
                            input[type="checkbox"]:checked {
                                appearance: none;
                                border-radius: 4px;
                                border: 1.5px solid #EE4950;
                                &::before{
                                    content: "✔";
                                    color: #EE4950;
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    font-size: 16px;
                                    font-weight: 700;
                                }
                                
                            }
    
                            label {
                                color: var(--primary-white-1, #E0E4E7);
    
                                /* tittle/16 M */
                                font-family: "DM Sans";
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                            }
                        }
    
                        .forgotPassword {
                            text-decoration: none;
                            color: #EE4950;
    
                            /* tittle/16 M */
                            font-family: "DM Sans";
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                        }
                    }
                }
            }
    
            .right {
                position: relative;
                display: flex;
                align-items: center;
                height: 100%;
    
                .eclipse {
                    position: absolute;
                    border-radius: 572px;
                    background: rgba(188, 62, 100, 1);
                    filter: blur(300px);
                    width: 572px;
                    height: 572px;
                    flex-shrink: 0;
                    z-index: 0;
                }
    
                img {
                    width: 690px;
                    height: auto;
                    z-index: 1;
                }
    
            }
        }
    }    
}
@media screen and (min-width: $breakpoint-mobile) and (max-width: $breakpoint-tablet) {
    .login {
        .container {
            display: flex;
            justify-content: center;
            overflow: hidden;
            height: 600px;
            margin-bottom: 0;
            padding-bottom: 0;
    
            .left {
                display: flex;
                align-items: center;
                padding: 60px auto auto 60px;
    
                .form {
    
                    padding: 16px 70px;
                    box-sizing: border-box;
    
                    .top {
                        display: inline-flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 13px;
                        margin-bottom: 56px;
    
                        .title {
                            color: var(--gray-white, #FFF);
    
                            /* Heading/H1 */
                            font-family: "DM Sans";
                            font-size: 30px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            margin: 0;
                        }
    
                        .subtitle {
                            color: var(--gray-dark-4, #7C7C8D);
    
                            /* tittle/18R */
                            font-family: "DM Sans";
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin: 0;
    
                            a {
                                text-decoration: none;
                                color: #EE4950;
    
                                /* tittle/18 M */
                                font-family: "DM Sans";
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                            }
                        }
                    }
    
                    .googleSignIn {
                        margin-bottom: 24px;
                    }
    
                    .formFields {
                        display: flex;
                        width: 506px;
                        padding: 20px 0px;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 14px;
                        box-sizing: border-box;
                        margin: 24px auto;
                    }
    
                    .terms {
                        display: flex;
                        justify-content: space-between;
    
                        .rememberMe {
                            text-wrap: nowrap;
                            margin-bottom: 30px;
                            display: flex;
                            gap: 13px;
                            align-items: center;
    
                            input[type="checkbox"] {
                                width: 20px;
                                height: 20px;
                                border-radius: 4px;
                                border: 1.5px solid var(--gray-dark-4, #7C7C8D);
                                // background: #a02a2a;
                                accent-color: transparent;
                                appearance: none;
                                
                            }
                            input[type="checkbox"]:checked {
                                appearance: none;
                                border-radius: 4px;
                                border: 1.5px solid #EE4950;
                                &::before{
                                    content: "✔";
                                    color: #EE4950;
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    font-size: 16px;
                                    font-weight: 700;
                                }
                                
                            }
    
                            label {
                                color: var(--primary-white-1, #E0E4E7);
    
                                /* tittle/16 M */
                                font-family: "DM Sans";
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                            }
                        }
    
                        .forgotPassword {
                            text-decoration: none;
                            color: #EE4950;
    
                            /* tittle/16 M */
                            font-family: "DM Sans";
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                        }
                    }
                }
            }
    
            .right {
                position: relative;
                display: none;
                align-items: center;
                height: 100%;
    
                .eclipse {
                    position: absolute;
                    border-radius: 572px;
                    background: rgba(188, 62, 100, 1);
                    filter: blur(300px);
                    width: 572px;
                    height: 572px;
                    flex-shrink: 0;
                    z-index: 0;
                }
    
                img {
                    width: 690px;
                    height: auto;
                    z-index: 1;
                }
    
            }
        }
    }    
}
@media screen and (max-width: $breakpoint-mobile) {
    .login {
        .container {
            display: flex;
            justify-content: space-between;
            // overflow: hidden;
            height: 770px;
            margin-bottom: 0;
            padding-bottom: 0;
            width: 100%;
            // background-color: #7C7C8D;
            .left {
                display: flex;
                align-items: center;
                padding: 60px auto auto 60px;
                box-sizing: border-box;
                width:100%;
                .form {
    
                    padding: 20px 20px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    // background-color: teal;
                    .top {
                        display: inline-flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 16px;
                        margin-bottom: 76px;
    
                        .title {
                            color: var(--gray-white, #FFF);
    
                            /* Heading/H1 */
                            font-family: "DM Sans";
                            font-size: 30px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            margin: 0;
                        }
    
                        .subtitle {
                            color: var(--gray-dark-4, #7C7C8D);
    
                            /* tittle/18R */
                            font-family: "DM Sans";
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin: 0;
    
                            a {
                                text-decoration: none;
                                color: #EE4950;
    
                                /* tittle/18 M */
                                font-family: "DM Sans";
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                            }
                        }
                    }
    
                    .googleSignIn {
                        margin-bottom: 24px;
                    }
    
                    .formFields {
                        display: flex;
                        width: 100%;
                        padding: 20px 0px;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 14px;
                        box-sizing: border-box;
                        margin: 24px auto;
                        // background: red;
                    }
    
                    .terms {
                        display: flex;
                        justify-content: space-between;
                        
                        .rememberMe {
                            text-wrap: nowrap;
                            margin-bottom: 30px;
                            display: flex;
                            gap: 13px;
                            align-items: center;
    
                            input[type="checkbox"] {
                                width: 20px;
                                height: 20px;
                                border-radius: 4px;
                                border: 1.5px solid var(--gray-dark-4, #7C7C8D);
                                // background: #a02a2a;
                                accent-color: transparent;
                                appearance: none;
                                
                            }
                            input[type="checkbox"]:checked {
                                appearance: none;
                                border-radius: 4px;
                                border: 1.5px solid #EE4950;
                                &::before{
                                    content: "✔";
                                    color: #EE4950;
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    font-size: 16px;
                                    font-weight: 700;
                                }
                                
                            }
    
                            label {
                                color: var(--primary-white-1, #E0E4E7);
    
                                /* tittle/16 M */
                                font-family: "DM Sans";
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                            }
                        }
    
                        .forgotPassword {
                            text-decoration: none;
                            color: #EE4950;
    
                            /* tittle/16 M */
                            font-family: "DM Sans";
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                        }
                    }
                }
            }
    
            .right {
                display: none;
    
            }
        }
    }   
}